.nurse_dashboard_buttons_wrap {
  padding-top: 25px;
  margin-top: 5px;
  display: flex;
  justify-content: space-around;
}

.nurse_button_common_styles {
  display: block;
  justify-content: center;
  height: 115px;
  width: 16%;
  border-radius: 7px !important;
  color: #ffffff !important;
  /* font-family: "Roboto" !important; */
  font-size: 1.5rem !important;
  font-weight: 500;
  text-decoration: none !important
}

.nurse_button1 {
  background: #510F30 0% 0% no-repeat padding-box !important;
}

.nurse_button2 {
  background: #00A49A 0% 0% no-repeat padding-box !important;
}

.nurse_button3 {
  background: #00E8D2 0% 0% no-repeat padding-box !important;
}

.nurse_button4 {
  background: #FF0068 0% 0% no-repeat padding-box !important;
}

.nurse_button5 {
  background: #F49C7A 0% 0% no-repeat padding-box !important;
}

.nurse_button6 {
  background: transparent linear-gradient(245deg, #00887A 0%, #00E8D2 100%) 0% 0% no-repeat padding-box !important;
}

.nurse_button_text {
  margin-top: 21px !important;
  display: flex !important;
  justify-content: center !important;
  margin-bottom: 8px !important;
  color: #fff !important;
  font-family: 'Roboto' !important;
  font-size: 16px !important;
  font-weight: 500 !important;  
}

.manage_add_div{
  width: 30% !important
}

.divider_container {
  display: flex;
  justify-content: center;
}

.divider_1px {
  height: 0.5px;
  border-radius: 20%;
  width: 85%;
  background-color: #ffffff;
}

.nurse_dashboard_numeric_wrap {
  margin-top: 7px;
  display: flex;
  justify-content: center;
  color: #fff;
}

.nurse_dash_numeric_value {
  font-size: 3rem;
}

@media only screen and (max-width: 1060px) {
  .nurse_button_common_styles {
    height: 90px;
    width: 140px;
    text-decoration: none !important
  }

  .nurse_dashboard_numeric_wrap {
    margin-top: 5px;
  }
}

@media only screen and (max-width: 830px) {
  .nurse_button_common_styles {
    height: 750px;
    width: 120px;
    text-decoration: none !important
  }

  .nurse_button_text {
    margin-top: 7px;
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
  }

  .nurse_dashboard_numeric_wrap {
    margin-top: 3px;
  }
}

.dashboard-date-range-picker {
  position: absolute;
  right: 30px;
}


.arabic_cls .nurse_button1 {
  order: 6;
}

.arabic_cls .nurse_button2 {
  order: 5;
}

.arabic_cls .nurse_button3 {
  order: 4;
}

.arabic_cls .nurse_button4 {
  order: 3;
}

.arabic_cls .nurse_button5 {
  order: 2;
}

.arabic_cls .nurse_button6 {
  order: 1;
}
a:hover {
  color: #23527c;
  /* text-decoration: underline; */
}

.Req {
  color: #ff0068;
  font-weight: bold;
}
.walkIn .labeltxt,
.walkIn .active_p {
  font-weight: bold !important;
  font-size: 16px !important;
  color: #510F30 !important;
}
.training_adjusts{
  margin-bottom: 10px !important;
}