.icon_settings {
    color: white;
    font-size: 25px;
}

.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    font-size: 13px;
    padding-bottom: 3px;
    margin-top: 10px !important;
}

.btn_par_hide {
    display: none;
}

.stepper___days {
    position: relative;
    right: 0%;
    bottom: 112px;
    font-size: 10px;
    margin-left: 25%;
    left: 15px;
}

.steeper_info{
    font-size: 10px;
    display: flex;
    position: relative;
    bottom: 115px;
    justify-content: space-around;
    /* margin-top: 30px; */
}

.steeper_info div{
    width: 66px;
}

.steeper_info div:nth-child(2){
    position: relative;
    left: 5px;
}

.steeper_Container{
    height: 120px;
    position: relative;
}

.MuiStepConnector-vertical{
    padding: 0px !important;
    margin-left: 16px !important;
}
.MuiStepConnector-lineVertical
{
    min-height: 100px !important;
}

.MuiStepLabel-label{
    font-size: 16px !important;
}