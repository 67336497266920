.cell_eye {
  padding-left: 0px !important;
}

.clr_class {
  font-family: "Roboto" !important;
}

.customer_history_table_div table thead tr th:nth-child(1),
.customer_history_table_div table tbody tr th:nth-child(1),
.customer_history_table_div table thead tr th:nth-child(3),
.customer_history_table_div table tbody tr td:nth-child(3),
.customer_history_table_div table thead tr th:nth-child(4),
.customer_history_table_div table tbody tr td:nth-child(4),
.customer_history_table_div table thead tr th:nth-child(5),
.customer_history_table_div table tbody tr td:nth-child(5),
.customer_history_table_div table thead tr th:nth-child(7),
.customer_history_table_div table tbody tr td:nth-child(7),
.customer_history_sub_table_div table thead tr th:nth-child(1),
.customer_history_sub_table_div table tbody tr th:nth-child(1),
.customer_history_sub_table_div table thead tr th:nth-child(3),
.customer_history_sub_table_div table tbody tr td:nth-child(3),
.customer_history_sub_table_div table thead tr th:nth-child(4),
.customer_history_sub_table_div table tbody tr td:nth-child(4),
.customer_history_sub_table_div table thead tr th:nth-child(5),
.customer_history_sub_table_div table tbody tr td:nth-child(5),
.customer_history_sub_table_div table thead tr th:nth-child(6),
.customer_history_sub_table_div table tbody tr td:nth-child(6),
.customer_history_sub_table_div table thead tr th:nth-child(7),
.customer_history_sub_table_div table tbody tr td:nth-child(7),
.customer_history_sub_table_div table thead tr th:nth-child(8),
.customer_history_sub_table_div table tbody tr td:nth-child(8) {
  /* text-align: center; */
  justify-content: center !important;
}




.arabic_cls .customer_history_table_div table thead tr th:nth-child(7),
.arabic_cls .customer_history_table_div table tbody tr th:nth-child(7),
.arabic_cls .customer_history_table_div table thead tr th:nth-child(5),
.arabic_cls .customer_history_table_div table tbody tr td:nth-child(5),
.arabic_cls .customer_history_table_div table thead tr th:nth-child(4),
.arabic_cls .customer_history_table_div table tbody tr td:nth-child(4),
.arabic_cls .customer_history_table_div table thead tr th:nth-child(3),
.arabic_cls .customer_history_table_div table tbody tr td:nth-child(3),
.arabic_cls .customer_history_table_div table thead tr th:nth-child(1),
.arabic_cls .customer_history_table_div table tbody tr td:nth-child(1) {
  text-align: center;
}

.arabic_cls .customer_history_table_div table thead tr th:nth-child(1) {
  text-align: end !important;
}

.arabic_cls .customer_history_sub_table_div table thead tr th:nth-child(1) {
  text-align: end !important;
}

.arabic_cls .customer_history_table_div table thead tr th:nth-child(2),
.arabic_cls .customer_history_table_div table tbody tr td:nth-child(2),
.arabic_cls .customer_history_table_div table thead tr th:nth-child(6),
.arabic_cls .customer_history_table_div table tbody tr td:nth-child(6) {
  text-align: right;
}

.arabic_cls .customer_history_sub_table_div table thead tr th:nth-child(8),
.arabic_cls .customer_history_sub_table_div table tbody tr th:nth-child(8),
.arabic_cls .customer_history_sub_table_div table thead tr th:nth-child(6),
.arabic_cls .customer_history_sub_table_div table tbody tr td:nth-child(6),
.arabic_cls .customer_history_sub_table_div table thead tr th:nth-child(5),
.arabic_cls .customer_history_sub_table_div table tbody tr td:nth-child(5),
.arabic_cls .customer_history_sub_table_div table thead tr th:nth-child(4),
.arabic_cls .customer_history_sub_table_div table tbody tr td:nth-child(4),
.arabic_cls .customer_history_sub_table_div table thead tr th:nth-child(3),
.arabic_cls .customer_history_sub_table_div table tbody tr td:nth-child(3),
.arabic_cls .customer_history_sub_table_div table thead tr th:nth-child(2),
.arabic_cls .customer_history_sub_table_div table tbody tr td:nth-child(2),
.arabic_cls .customer_history_sub_table_div table thead tr th:nth-child(1),
.arabic_cls .customer_history_sub_table_div table tbody tr td:nth-child(1) {
  text-align: center;
}

/* .arabic_cls .customer_history_sub_table_div table thead tr th:nth-child(2),
.arabic_cls .customer_history_sub_table_div table tbody tr td:nth-child(2) {
  text-align: right;
} */

/* .arabic_cls .customer_history_sub_table_div table thead tr th:nth-child(6),
.arabic_cls .customer_history_sub_table_div table tbody tr td:nth-child(6) {
  text-align: right;
} */

.arabic_cls .customer_history_sub_table_div table thead tr th:nth-child(7),
.arabic_cls .customer_history_sub_table_div table tbody tr td:nth-child(7) {
  text-align: right;
}

.left_arrow_dashboard {
  cursor: pointer;
  font-size: 4rem !important;
  fill: black !important;
  margin-top: -3px;
}