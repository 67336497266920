.basic_detailsmodal .formdiv .labeltxt {
  padding-bottom: 0px !important;
  font-size: 16px !important;
  color: black;
}

.basic_detailsmodal .formdiv .brdrcls {
  font-size: 14px !important;
  color: #707070 !important;
  padding: 8px !important;
  margin-top: 10px;
}

.basic_detailsmodal .formdiv {
  margin-bottom: 10px !important;
}

.basic_address_details {
  width: 80%;
  margin: auto;
}

.tag_closeicons_container .ant-tag .anticon-close {
  font-size: 1rem !important;
  position: relative;
  top: -2px;
  padding: 2px;
  color: #f5222d !important;
}

.tag_closeicons_container .ant-tag {
  background-color: #03bed5 !important;
  color: #fff !important;
  padding: 5px !important;
  border-radius: 3px !important;
  border: none !important;
  font-family: "Roboto" !important;
}

.tag_closeicons_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
  left: 4px;
}

.buttons_cont {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 20px;
}

.profile_Cancel {
  font-size: 16px !important;
  background-color: rgb(201, 201, 201) !important;
  font-family: "Roboto";
  margin-right: 20px !important;
  padding: 6px 16px !important;
  text-transform: none !important;
  border-radius: 50px !important;
}

.profile_Submit {
  font-size: 16px !important;
  background-color: var(--app-theme) !important;
  font-family: "Roboto";
  color: #fff !important;
  padding: 6px 16px !important;
  text-transform: none !important;
  border-radius: 50px !important;
}

.profile_cont {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.bank-name-details {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  align-items: center;
  width: 100%;
  height: 34px;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 14px;
  color: #707070;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
}

.arabic_cls .basic_details_container .formdiv .labeltxt,
.arabic_cls .basic_details_container .formdiv .brdrcls,
.arabic_cls .bank_detail_div .formdiv .Errormsg {
  text-align: right;
}

.bank-details-title {
  font-family: titillium web !important;
  margin-top: -7px;
}

.Errormsg {
  font-size: 12px;
  /* margin-top: 2%; */
  color: #ff0068;
  white-space: nowrap;
}

.arabic-direct {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.ar_bank_icon {
  margin-left: 10px;
}
.all_deatails.contract_form_label, .contact_align_lab .labeltxt {
 
  font-weight: 400 !important;
}
.arabic_cls .all_deatails.contract_form_label,
.contact_align_lab .formdiv .Errormsg{
  direction: ltr !important;
}