.bank_details_modal {
  width: 100% !important;
  height: 550px !important;
  overflow: visible !important;
}

.page-title {
  font-weight: bold;
  color: var(--app-theme);
}

.key-label {
  padding-left: 3%;
  padding-right: 3%;
}

.button-align {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-align .update-btn {
  margin-left: 5%;
  color: #fff;
  font-size: 16px;
}

.button-align .cancel-btn {
  color: #000;
  font-size: 16px;
}

.bank_details_buttons {
  display: flex;
  justify-content: center;
  margin-top: 12px;
}

.update_button {
  font-size: 16px !important;
  background-color: var(--app-theme) !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  text-transform: none !important;
  border-radius: 50px !important;
  color: #fff !important;
  padding: 6px 15px !important;
}

.cancel_button {
  font-size: 16px !important;
  margin-right: 15px !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  text-transform: none !important;
  border-radius: 50px !important;
  padding: 6px 15px !important;
}

.bank_detail_div .formdiv {
  margin-bottom: 10px;
}

.bank_detail_div .formdiv .labeltxt {
  font-size: 16px !important;
  color: black !important;
}

.bank_detail_div input {
  font-size: 14px !important;
  color: #707070 !important;
}

.contextIcon {
  color: #ff0068;
  position: relative;
  left: 3px;
}

.MuiGrid-container {
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}