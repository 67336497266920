.formdiv {
  display: inline-block;
  width: 100%;
  text-align: left;
  position: relative;
}
.formdiv .labeltxt {
  color: #333;
  display: block;
  padding-bottom: 2px;
  font-size: 15px;
  font-weight: 400;
  font-size: 16px !important;
}
.formdiv .brdrcls {
  width: 100%;
  padding: 6px;
  border: none;
  outline: 0;
  font-size: 13px;
  border-radius: 2px !important;
  border: 1px solid #ccc;
  font-family: "Roboto" !important;
  color: #707070 !important;
  /* margin-bottom: 10px; */
  /* box-shadow: 0 0 1.5px 0px #ccc; */
}
.formdiv .MuiFormControl-root .MuiTextField-root .MuiFormControl-marginNormal {
  box-shadow: transparent;
}
.formdiv .radiobtncolor {
  color: var(--app-theme)!important;
}
.formdiv .datepickerchnge input {
  width: 100% !important;
  padding: 20px;
  border-radius: 0px;
}
.formdiv .selectbox .ant-select-selection--single {
  height: unset !important;
  padding: 4px;
  outline: 0;
  border-radius: 2px !important;
  border: 0;
  border: 1px solid #ccc;
  font-family: "Roboto" !important;
  /* box-shadow: 0 0 1.5px 0px #ccc; */
  
}
.formdiv .selectbox {
  /*   z-index: 1400  !important;*/
  width: 100% !important;
  border-radius: 0;
  outline: unset;
}

.formdiv .ant-select-selection__rendered {
  position: relative;
  display: block;
  margin-right: 0;
  margin-left: 3px;
  line-height: 33px;
}
.formdiv .ant-select-search--inline .ant-select-search__field {
  border: none;
  box-shadow: none;
}
.formdiv .ant-select-search__field__wrap input {
  padding-left: 0px;
}
.formdiv .Errormsg {
  font-size: 12px;
  /*  position: absolute;
    bottom: -22px;*/
  /* left: 2px; */
  color: #ff0068;
  white-space: nowrap;
  /* margin-top: -1%; */
  margin-top: 5px;
}

.ant-select-dropdown {
  z-index: 1400;
}
.ant-calendar-picker {
  z-index: 1410;
}
.ant-calendar-picker-container {
  z-index: 1410 !important;
}
.formdiv .MuiFormControl-marginNormal {
  margin-top: 0px !important;
}
.formdiv .MuiFormControl-marginNormal {
  margin-top: 0px !important;
  /* border:1px solid #ccc !important; */
  border-radius: 2px !important;
  width: 100%;
}
.formdiv .MuiInputBase-input {
  font-size: 1.25rem !important;
  font-family: "Avenir-Black" !important;
  color: grey !important;
  margin-left: 5px !important;
  border-radius: 2px;
  font-family: "Roboto" !important;
}
.MuiInput-underline:before {
  border-bottom: 0px !important;
}
.MuiInput-underline:after {
  border-bottom: 0px !important;
}
.MuiFormControl-marginNormal {
  margin-bottom: 0px !important;
}
.radiobtncolor {
  font-family: "Roboto";
}

/* for datepicker */

.MuiPickersCalendarHeader-dayLabel {
  color: black !important;
  font-size: 14px !important;
}

.MuiTypography-colorInherit {
  /* color: black !important; */
  font-size: 14px !important;
  margin-top: 10px !important;
}
.MuiPickersToolbarText-toolbarTxt{
  /* color: white; */
  font-size: 14px;
}

.MuiPickersDay-daySelected {
  background-color: var(--app-theme)!important;
}

.MuiDialogActions-root.MuiDialogActions-spacing .MuiButton-label {
  font-size: 12px;
}

.formdiv .MuiInputBase-inputAdornedEnd {
  color: #797777;
}
.MuiFormHelperText-root.Mui-error {
  display: none !important;
}

.formdiv
  .KeyboardDatePicker_class
  .MuiInputAdornment-positionEnd
  .MuiButtonBase-root:focus {
  outline: none;
}

.dutyHoursRadioLabel {
  color: #666666;
  font-size: 15px;
  margin-bottom: 0px;
  margin-top: 8px;
  margin-right: 7%;
}

.dutyDiv .radiobtncolor {
  color: var(--app-theme)!important;
}


.arabic_cls .formdiv .labeltxt 
{
    text-align: right;
}

.arabic_cls .formdiv .brdrcls 
{
  direction: ltr;
    text-align: right;
}

.arabic_cls .Dialogmodal h6{
  text-align: end !important;
}

.MuiTypography-alignCenter {
  text-align: center;
  font-weight: 700;
}

.MuiPickersYear-yearSelected {
  margin: 10px 0;
  font-weight: 700;
  font-size: 16px;
}

.MuiTypography-subtitle1 {
  font-size: 13px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0.00938em;
}
.walkIn .formdiv .selectbox .ant-select-selection--single{
  width: 100%;
    max-height: 35px !important;
}
/* .formdiv .selectbox .ant-select-selection--single{
  border: none !important;
} */