.pharmacy_login_container .pharmacy_main_container {
	width: 70%;
	height: auto;
}

.pharmacy_login_container .inbox_icon_div {
	width: 20px;
	height: 20px;
	position: absolute;
}

.pharmacy_login_container .inbox_icon {
	width: 20px;
	height: 20px;
	margin-right: 38%;
}

.pharmacy_login_container .pharmacy_grid_container {
	width: 100%;
	height: auto !important;
	display: flex;
	align-items: center;
	justify-content: center;
}

.pharmacy_login_container .logo_image {
	width: 90% !important;
	height: 90% !important;
}

.pharmacy_login_container .logo_image {
	width: 100%;
	height: 100%;
}

.pharmacy_login_container .logo_image_div {
	width: 100px;
	height: 100px;
}

.logo_container {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 15px;
}

.pharmacy_login_container .logo_div {
	width: 100px;
	height: auto;
}

.pharmacy_login_container .pharmacy_paper_div {
	align-items: center;
	justify-content: center;
}

.pharmacy_login_container .pharmacy_image_grid {
	width: 100%;
	height: 100%;
	display: flex;
}

.pharmacy_login_container .pharmacy_image {
	width: 100%;
	height: 100vh;
	position: relative;
}

.pharmacy_login_container .pharmacy_image_login {
	width: 100%;
	height: 100%;
	position: relative;
}

.pharmacy_login_container .pharmacy_image_div {
	width: 100%;
	height: 100%;
}

.pharmacy_login_container .lock_icon_container {
	width: 100%;
	height: 100%;
	top: 170px;
	left: 481px;
	position: absolute;
}

.pharmacy_login_container .lock_icon_div {
	width: 20px;
	height: 20px;
}

.pharmacy_email_container {
	border: none !important;
}

.pharmacy_login_container .lock_icon {
	width: 100%;
	height: 100%;
}

.pharmacy_login_container .password_container {
	font-family: "Avenir-Black" !important;
	margin-top: 10px !important;
}

@font-face {
	font-family: "Avenir-Black";
	src: url("../../Font/Avenir-Black.ttf") format("truetype");
}

body {
	font-family: "Avenir-Black";
	margin: 0;
	line-height: 1.5 !important;
}

.pharmacy_login_container .main_containerdiv {
	height: 100% !important;
	width: 100% !important;
}

.pharmacy_login_container .pharmacy_image {
	width: 100%;
	box-shadow: 0 0 2px 2px #e8e8e8;
}

.pharmacy_login_container .Welcometext {
	font-size: 3.5rem !important;
	color: #510F30 !important;
}

.pharmacy_Welcometext-container {
	height: 15%;
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: flex-end;
	font-family: "Roboto";
}

.pharmacy_login_container .Submit {
	color: rgb(80, 174, 89) !important;
	text-decoration: none !important;
	cursor: pointer;
	font-size: 20px;
}

.pharmacy_login_container .submit_container {
	text-align: right;
	margin-top: 20px;
}

.pharmacy_login_container .cancelbutton {
	text-decoration: none !important;
	cursor: pointer;
	font-family: "Avenir-Black" !important;
}

.pharmacy_login_container .pharmacy_image_container {
	width: 100%;
	height: 100%;
	display: flex;
}

.pharmacy_login_container .parent {
	position: relative;
}

.cancel_container {
	margin-top: 23px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	margin-bottom: 10px;
}

.cancelbutton {
	color: #510F30 !important;
}

.cancelbutton:hover {
	cursor: pointer !important;
}

.clinic_login_container ::placeholder {
	font-size: 10px;
	font-family: "Avenir-Black" !important;
}

.login_button_container {
	padding-top: 25px !important;
}

.pharmacy_login_container .login {
	background-color: white;
	display: flex;
	border-radius: 50px;
	/* height: 24%; */
	height: 55px;
	width: 35%;
	cursor: pointer;
	color: white;
	background-color: hsla(88, 79%, 46%, 0.85);
	font-size: 2.5rem;
	align-items: center;
	cursor: pointer;
	justify-content: center;
	border: 1px solid #ccc;
	border: none;
	font-family: "Avenir-Black";
	background: transparent linear-gradient(180deg, #510f30, #510f30) 0% 0% no-repeat padding-box;
	box-shadow: 0px 6px 6px #00000030;
	border-radius: 34px;
	margin: auto;
}

.pharmacy_login_container .label+.MuiInput-formControl {
	margin-top: 22px !important;
}

.pharmacy_login_container button:focus {
	outline: none !important;
}

@media only screen and (max-width: 768px) {
	.pharmacy_login_container .pharmacy_image {
		display: none;
		position: relative;
	}

	.pharmacy_login_container .pharmacy_main_container {
		width: 100%;
		height: auto;
		/* margin-top: 177px; */
	}

	.pharmacy_login_container .pharmacy_grid_container {
		width: 100%;
		height: auto !important;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.pharmacy_login_container .pharmacy_text {
		/* top: 14%; */

		position: fixed;
	}
}

@media only screen and (max-width: 959px) {
	.pharmacy_login_container .pharmacy_image_grid {
		display: none;
	}

	.pharmacy_login_container .pharmacy_grid_container {
		width: 100%;
		height: 100vh !important;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.pharmacy_login_container .pharmacy_main_container {
		width: 70%;
		height: auto;
	}

	.pharmacy_login_container .pharmacy_main_container {
		width: 45%;
	}

	.pharmacy_login_container .Welcometext {
		font-size: 4rem !important;
	}
}

@media only screen and (max-width: 1024px) {
	.trainer_text {
		margin-top: -169px;
	}
}

@media only screen and (max-width: 1058px) {
	.pharmacy_login_container .Welcometext {
		font-size: 3rem !important;
	}
}

.pharmacy_login_container .MuiInputBase-root {
	font-family: "Avenir-Black" !important;
}

.pharmacy_login_container .MuiInputBase-input {
	/* font-size: 15px !important;
	height: 3rem !important;
	font-family: "Avenir-Black" !important;
	padding: 6px 0 4px !important; */

	font-size: 15px !important;
	height: 3rem !important;
	font-family: "Roboto" !important;
	padding: 6px 0 4px !important;
	font-weight: 400 !important;
}

.pharmacy_login_container .MuiInputLabel-formControl {
	font-size: 1.5rem !important;
	top: 10px !important;
	font-family: "Avenir-Black" !important;
}

.pharmacy_login_container .MuiInput-underline:after {
	transition: none !important;
	/* border-bottom: none !important; */
	pointer-events: none;
}

.pharmacy_login_container .MuiInput-underline:before {
	left: 0;
	right: 0;
	bottom: 0;
	transition: none !important;
	pointer-events: none;
	/* border-bottom: 2px solid #000000ed !important; */
}

.pharmacy_login_container .MuiInputLabel-root.Mui-focused {
	color: grey !important;
	font-family: "Avenir-Black" !important;
	border-bottom: red !important;
}

.pharmacy_login_container .MuiFormControl-root {
	width: 100% !important;
	/* margin-left: 46px; */
	/* margin-right: 46px; */
	border-bottom: 1px solid #333333b0 !important;
}

.pharmacy_login_container .MuiFormLabel-root {
	/* line-height: 0!important;*/
	font-family: "Avenir-Black" !important;
}

.pharmacy_login_container .inbox_icon {
	width: 20px;
	height: 20px;
}

.pharmacy_text {
	position: absolute;
	top: -150px;
	left: -452px;
	align-items: center;
	font-size: 4rem;
	color: white;
	font-family: "Avenir-Black" !important;
	transform: rotate(-90deg);
	right: 617px;
	text-shadow: 0px 3px 6px #000000e6;
}

.logineye_icon {
	width: 30px;
	height: 30px;
}

/* .password_container .MuiIconButton-root {
	padding: 6px !important;
} */


.reject_btn {
	background: #F45959 0% 0% no-repeat padding-box !important;
	box-shadow: 0px 2px 5px #00000033 !important;
	border-radius: 25px !important;
	opacity: 1 !important;
	color: #fff !important;
	font-size: 16px !important;
	padding: 6px 16px !important;
	font-weight: 500 !important;
	font-family: "Roboto" !important;
	text-transform: capitalize !important;
	margin-right: 10% !important;
	outline: none !important;
}

.accept_btn {
	background: var(--app-theme) 0% 0% no-repeat padding-box !important;
	box-shadow: 0px 2px 5px #00000033 !important;
	border-radius: 25px !important;
	opacity: 1 !important;
	color: #fff !important;
	font-size: 16px !important;
	padding: 6px 16px !important;
	font-weight: 500 !important;
	font-family: "Roboto" !important;
	text-transform: capitalize !important;

}

.approve_popu_main_div {

	width: 80%;
	margin-top: 3rem;
	margin-bottom: 3rem;
	margin-right: auto;
	margin-left: auto;
}


.approval_img_container .ant-upload.ant-upload-select-picture-card {
	background-color: #fff !important;
	border: 0px !important;
	width: 90px !important;
	border-radius: 50% !important;
	border: none !important;
	position: relative;
	height: 90px !important;
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12) !important;
}

.approval_img_container .ant-upload {
	padding: 0px !important;
}

.m-signature-pad {
	width: 300px;
	/* border: 1px solid; */
	margin: auto;
}

.contract_approve_btn_div {
	display: flex;
	justify-content: center;
	align-items: center;
}

.signature_icon {
	color: var(--app-theme) !important;
	font-size: 2rem !important;
	cursor: pointer;
}

.contract_approve_btn_div button {
	background-color: var(--app-theme);
	border-radius: 50px;
	padding: 5px 20px 5px 20px;
	color: white;
	border-color: var(--app-theme);
	font-size: 16px;
}

.contract_approval_popup~div {
	display: none !important;
}

.sign_name_div .formdiv .labeltxt {
	font-size: 15px !important;
	color: rgb(102, 102, 102) !important;
	font-weight: 500 !important;
}

.sign_pic_align {
	position: absolute;
	bottom: 0px;
	right: 7px;
	top: 70px;
	background: #d9d9d9;
	height: 20px;
	width: 20px;
	border-radius: 50%;
	text-align: center;
	justify-content: center;
}

.m-signature-pad .clear {
	display: none;
}

.approve_popu_main_div .approval_img_container .ant-upload-picture-card-wrapper {
	justify-content: left !important;
}

.approval_check_popup .MuiDialog-paperFullWidth,
.signature_popup .MuiDialog-paperFullWidth {
	min-width: 30% !important;
	max-width: 30% !important;
	width: auto !important;
}


.contract_alert_popup .MuiDialog-paperFullWidth {
	min-width: 20% !important;
	max-width: 25% !important;
	width: auto !important;
}

/* Arabic language css change */

.arabic_cls .MuiInputLabel-formControl {
	right: 0px !important;
	left: auto !important;
	/* transform: translate(0, 24px) scale(1); */
}

/*
.arabic_cls .MuiInputLabel-shrink
{
  transform: translate(0, 1.5px) scale(0.75) !important;
  transform-origin: top right !important;
}*/

.arabic_cls .contatct_approve_head {
	float: right;
}

.arabic_cls .contract_form_label,
.arabic_cls .contact_align_lab .labeltxt {
	text-align: right;
	width: 100%;
}

.arabic_cls .approve_popu_main_div {
	justify-content: flex-end !important;
}

.arabic_cls .approval_img_container .ant-upload.ant-upload-select-picture-card {
	margin-right: 0px;
}

.arabic_cls .contract_pic_align {
	right: 70px;
}

.arabic_cls .contract_form_label {
	justify-content: flex-end;
}

.arabic_cls .contract_form_label div {
	order: 1;
}

.arabic_cls .approval_img_container .ant-upload-select-picture-card {
	float: right !important;
}









/* =================================================Diet Css=========================================================== */




.pharmacy_login_container .pharmacy_main_container {
	width: 70%;
	height: auto;
}

.pharmacy_login_container .inbox_icon_div {
	width: 20px;
	height: 20px;
	position: absolute;
}

.pharmacy_login_container .inbox_icon {
	width: 20px;
	height: 20px;
}

.pharmacy_login_container .pharmacy_grid_container {
	width: 100%;
	height: auto !important;
	display: flex;
	align-items: center;
	justify-content: center;
}

.pharmacy_login_container .logo_image {
	width: 100%;
	height: 100%;
}

.pharmacy_login_container .logo_image {
	width: 100%;
	height: 100%;
}

.pharmacy_login_container .logo_image_div {
	width: 100px;
	height: 100px;
}

.logo_container {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 15px;
}

.pharmacy_login_container .logo_div {
	width: 100px;
	height: auto;
}

.pharmacy_login_container .pharmacy_paper_div {
	align-items: center;
	justify-content: center;
}

.pharmacy_login_container .pharmacy_image_grid {
	width: 100%;
	height: 100%;
	display: flex;
}

.pharmacy_login_container .pharmacy_image {
	width: 100%;
	height: 100vh;
	position: relative;
}

.pharmacy_login_container .pharmacy_image_login {
	width: 100%;
	height: 100%;
	position: relative;
}

.pharmacy_login_container .pharmacy_image_div {
	width: 100%;
	height: 100%;
}

.pharmacy_login_container .lock_icon_container {
	width: 100%;
	height: 100%;
	top: 170px;
	left: 481px;
	position: absolute;
}

.pharmacy_login_container .lock_icon_div {
	width: 20px;
	height: 20px;
}

.pharmacy_email_container {
	border: none !important;
}

.pharmacy_login_container .lock_icon {
	width: 100%;
	height: 100%;
}

.pharmacy_login_container .password_container {
	font-family: "Avenir-Black" !important;
	margin-top: 10px !important;
}

@font-face {
	font-family: "Avenir-Black";
	src: url("../../Font/Avenir-Black.ttf") format("truetype");
}

body {
	font-family: "Avenir-Black";
	margin: 0;
}

.pharmacy_login_container .main_containerdiv {
	height: 100% !important;
	width: 100% !important;
}

.pharmacy_login_container .pharmacy_image {
	width: 100%;
	box-shadow: 0 0 2px 2px #e8e8e8;
}

.pharmacy_login_container .Welcometext {
	font-size: 36px !important;
	color: #510f30 !important;
	font-family: 'Roboto';
	font-weight: 700;
}

.pharmacy_Welcometext-container {
	height: 15%;
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: flex-end;
	font-family: "Avenir,Black" !important;
}

.pharmacy_login_container .Submit {
	color: rgb(80, 174, 89) !important;
	text-decoration: none !important;
	cursor: pointer;
	font-size: 20px;
}

.pharmacy_login_container .submit_container {
	text-align: right;
	margin-top: 20px;
}

.pharmacy_login_container .cancelbutton {
	text-decoration: none !important;
	cursor: pointer;
	font-family: "Roboto" !important;
}

.pharmacy_login_container .pharmacy_image_container {
	width: 100%;
	height: 100%;
	display: flex;
}

.pharmacy_login_container .parent {
	position: relative;
}

.cancel_container {
	margin-top: 23px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	margin-bottom: 10px;
}

.cancelbutton {
	font-size: 14px !important;
	color: #ff0068 !important;
}

.cancelbutton:hover {
	/* cursor: pointer !important; */
}

.clinic_login_container ::placeholder {
	font-size: 10px;
	font-family: "Avenir-Black" !important;
}

.login_button_container {
	padding-top: 25px !important;
}

.loginbutton_1 {
	background-color: #510F30;
	color: white;
}

.pharmacy_login_container .login {
	background-color: white;
	display: flex;
	border-radius: 50px;
	/* height: 24%; */
	height: 55px;
	width: 35%;
	cursor: pointer;
	color: white;
	/* background-color: hsla(88, 79%, 46%, 0.85); */
	font-size: 2.5rem;
	align-items: center;
	cursor: pointer;
	justify-content: center;
	border: 1px solid #ccc;
	border: none;
	font-family: "Roboto";
	background: rgb(81, 15, 48);
	background: linear-gradient(180deg, rgba(81, 15, 48, 1) 0%, #ab7490 100%);
	box-shadow: 0px 6px 6px #00000030;
	border-radius: 34px;
	margin: auto;
}

.pharmacy_login_container .label+.MuiInput-formControl {
	margin-top: 22px !important;
}

.pharmacy_login_container button:focus {
	outline: none !important;
}

@media only screen and (max-width: 768px) {
	.pharmacy_login_container .pharmacy_image {
		display: none;
		position: relative;
	}

	.pharmacy_login_container .pharmacy_main_container {
		width: 100%;
		height: auto;
		/* margin-top: 177px; */
	}

	.pharmacy_login_container .pharmacy_grid_container {
		width: 100%;
		height: auto !important;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.pharmacy_login_container .pharmacy_text {
		top: 14%;

		position: absolute;
	}
}

@media only screen and (max-width: 959px) {
	.pharmacy_login_container .pharmacy_image_grid {
		display: none;
	}

	.pharmacy_login_container .pharmacy_grid_container {
		width: 100%;
		height: 100vh !important;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.pharmacy_login_container .pharmacy_main_container {
		width: 70%;
		height: auto;
	}

	.pharmacy_login_container .pharmacy_main_container {
		width: 45%;
	}

	.pharmacy_login_container .Welcometext {
		font-size: 4rem !important;
	}
}

@media only screen and (max-width: 1024px) {
	.trainer_text {
		margin-top: -169px;
	}
}

@media only screen and (max-width: 1058px) {
	.pharmacy_login_container .Welcometext {
		font-size: 3rem !important;
	}
}

.pharmacy_login_container .MuiInputBase-root {
	font-family: "Avenir-Black" !important;
}

.pharmacy_login_container .MuiInputBase-input {
	/* font-size: 15px !important; */
	font-size: 15px !important;
	height: 3rem !important;
	/* font-family: "Avenir-Black" !important; */
	padding: 6px 0 4px !important;
	color: black;
	font-weight: 400 !important;
	font-family: 'Roboto' !important;

}

.pharmacy_login_container .MuiInputLabel-formControl {
	font-size: 1.5rem !important;
	top: 10px !important;
}

.pharmacy_login_container .MuiInput-underline:after {
	transition: none !important;
	/* border-bottom: none !important; */
	pointer-events: none;
}

.pharmacy_login_container .MuiInput-underline:before {
	left: 0;
	right: 0;
	bottom: 0;
	transition: none !important;
	pointer-events: none;
	/* border-bottom: 2px solid #000000ed !important; */
}

.pharmacy_login_container .MuiInputLabel-root.Mui-focused {
	color: grey !important;
	font-family: "Avenir-Black" !important;
	border-bottom: red !important;
}

.pharmacy_login_container .MuiFormControl-root {
	width: 100% !important;
	/* margin-left: 46px; */
	/* margin-right: 46px; */
	border-bottom: 1px solid #333333b0 !important;
}

.pharmacy_login_container .MuiFormLabel-root {
	/* line-height: 0!important;*/
	/* font-family: "Avenir-Black" !important; */
	/* font-family: roboto !important; */
	color: rgba(0, 0, 0, 0.54);
	/* font-weight: 400; */
	font-weight: 600 !important;
}

.pharmacy_login_container .inbox_icon {
	width: 20px;
	height: 20px;
}

.pharmacy_text {
	position: absolute;
	top: -35%;
	left: -40%;
	align-items: center;
	font-size: 4rem;
	color: white;
	font-family: "Avenir-Black" !important;
	transform: rotate(-90deg);
	right: 617px;
	text-shadow: 0px 3px 6px #000000e6;
}

.logineye_icon {
	width: 30px;
	height: 30px;
}

/* .password_container .MuiIconButton-root {
	padding: 6px !important;
} */


.accept_btn {
	background: #510f30 0% 0% no-repeat padding-box !important;
	box-shadow: 0px 2px 5px #00000033 !important;
	border-radius: 25px !important;
	opacity: 1 !important;
	color: #fff !important;
	font-size: 16px !important;
	padding: 6px 25px !important;
	font-weight: 500 !important;
	font-family: "Roboto" !important;
	text-transform: capitalize !important;
}

.approve_popu_main_div {

	width: 80%;
	margin-top: 3rem;
	margin-bottom: 3rem;
	margin-right: auto;
	margin-left: auto;
}


.approval_img_container .ant-upload.ant-upload-select-picture-card {
	background-color: #fff !important;
	border: 0px !important;
	width: 90px !important;
	border-radius: 50% !important;
	border: none !important;
	position: relative;
	height: 90px !important;
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12) !important;
}

.approval_img_container .ant-upload {
	padding: 0px !important;
}

.m-signature-pad {
	width: 300px !important;
	/* border: 1px solid;
	 */
}

.m-signature-pad--body {
	canvas {
		width: 300px !important;
	}
}

.signature_icon {
	color: #510f30 !important;
	font-size: 2rem !important;
	cursor: pointer;
}

.contract_approval_popup~div {
	display: none !important;
}

.approve_popu_main_div .formdiv .labeltxt {
	font-size: 15px !important;
	color: rgb(102, 102, 102) !important;
	font-weight: 500 !important;
}



.m-signature-pad {
	width: 300px !important;
	/* border: 1px solid; */
	margin: auto;
}

.m-signature-pad .m-signature-pad--footer {
	display: none;
}

.contract_approve_btn_div {
	display: flex;
	justify-content: center;
	align-items: center;
}

.signature_icon {
	color: #510f30 !important;
	font-size: 2rem !important;
	cursor: pointer;
}

/* .contract_approve_btn_div button{
	background-color: #510f30;
	border-radius: 50px;
	padding: 6px 25px;
	color: white;
	border-color: #510f30;
	font-size: 16px !important;
	font-weight: 500;
  } */
.contract_approve_btn_div button {
	background-color: #510f30;
	/* border-radius: 50px; */
	padding: 6px 50px;
	color: white;
	/* border-color: white; */
	font-size: 16px !important;
	font-weight: 500;
	border-style: dotted;
	margin-top: 9px;
}

.MuiTypography-h6.alert_contract {
	font-weight: 600 !important;
	color: #510f30 !important
}

.succesimg {
	color: #510f30;
	font-family: 'Titillium Web' !important;
	font-size: 20px;
	display: flex;
	justify-content: center;
	margin-top: 10px;
	font-weight: 400 !important;
}

.contract_approval_popup~div {
	display: none !important;
}

.contract_pic_align {
	position: absolute;
	bottom: 0px;
	right: 0px;
	top: 65px;
	background: #d9d9d9;
	height: 20px;
	width: 20px;
	border-radius: 50%;
	text-align: center;
	justify-content: center;
}

.contract_accept_btn {
	background: #510f30 !important;
	font-size: 16px !important;
	padding: 6px 27px !important;
	font-weight: 500 !important;
}

/* .contatct_approve_head .alert_message{
   color: #510f30 !important;
   font-family: titillium web !important;
   text-transform: uppercase !important;
  } */
.contract_form_label {
	display: flex;
}

.contract_form_label,
.contact_align_lab .labeltxt {
	font-size: 15px !important;
	color: rgb(102, 102, 102) !important;
	font-weight: 500 !important;
}

.contatct_approve_head {
	margin-top: 6px !important;
}

.approval_check_popup .MuiDialog-paperFullWidth,
.signature_popup .MuiDialog-paperFullWidth {
	min-width: 26% !important;
	max-width: 23% !important;
	width: auto !important;
}

.titillium_font {
	font-family: titillium web !important;
}

/* .contract_alert_popup .MuiDialog-paperFullWidth
  {
	min-width: 20% !important;
	max-width: 25% !important;
	width: auto !important;
  } */
.contract_alert_popup .MuiDialog-paperFullWidth {
	width: 300px !important;
	height: 284px;
	display: flex;
	/* justify-content: center; */
}



.reject_btn {
	background: #ff0068 0% 0% no-repeat padding-box !important;
	box-shadow: 0px 2px 5px #00000033 !important;
	border-radius: 25px !important;
	opacity: 1 !important;
	color: #fff !important;
	font-size: 16px !important;
	padding: 6px 28px !important;
	font-weight: 500 !important;
	font-family: "Roboto" !important;
	text-transform: capitalize !important;
	margin-right: 5% !important;
	outline: none !important;
}

.approve_popu_main_div .approval_img_container .ant-upload-picture-card-wrapper {
	justify-content: left !important;
}

.arabic_cls .MuiInputLabel-formControl {
	right: 0px !important;
	left: auto !important;
	/* transform: translate(0, 24px) scale(1); */
}

/*
  .arabic_cls .MuiInputLabel-shrink
  {
	transform: translate(0, 1.5px) scale(0.75) !important;
	transform-origin: top right !important;
  }*/

.arabic_cls .contatct_approve_head {
	float: right;
}

.arabic_cls .contract_form_label,
.arabic_cls .contact_align_lab .labeltxt {
	text-align: right;
	width: 100%;
}

.arabic_cls .approve_popu_main_div .approval_img_container .ant-upload-picture-card-wrapper {
	justify-content: flex-end !important;
}

.arabic_cls .approval_img_container .ant-upload.ant-upload-select-picture-card {
	margin-right: 0px;
}

.arabic_cls .contract_pic_align {
	right: 70px;
}

.arabic_cls .contract_form_label {
	justify-content: flex-end;
}

.arabic_cls .contract_form_label div {
	order: 1;
}

.profile_details {
	display: flex;
	align-items: center;
	margin-bottom: 50px;
}

.profile_name_details {
	margin: 0px 1px 1px 21px;
	color: black;
	font-size: 15px;
	font-weight: 400;
}

.upload-img-circle1 {
	height: 65px !important;
	border-radius: 50%;
	width: 65px !important;
}

.terms_and_policies {
	display: flex;
	justify-content: space-between;
	margin-top: 25px;
}

.view_terms_policies {
	text-align: center;
	color: #00E8D2;
	position: relative;
	top: 5px;

}

.view_terms_policies:hover {
	cursor: pointer;
}

.signature_details {
	border: 1px solid #510f30;
	padding: 10px;
	width: 112px;
	text-align: center;
	margin-top: -5px;
}

.tom_logo {
	width: 75%;
	height: 36px;
	margin-top: 15px;
}

.policies_details {
	color: #510f30;
	font-weight: 600;
}

.header_details {
	box-shadow: 0px 0px 3.5px 0px #ccc;
	/* border-bottom: 1px solid #ccc; */
	/* display: none; */
	background-color: #510f30;
	height: 45px !important;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	justify-content: space-between !important;

}

.sig_container {
	border: 1px solid #510f30;
	height: 45px;
	width: 100%;
	display: flex;
	position: relative;
	top: 10px;
}

.sig_img {
	width: 100%;
	height: 43px;
}

.remove_container {
	background-color: #ff0068;
	color: #ffffff;
	/* width: fit-content; */
	display: flex;
	border-radius: 25px;
	height: -moz-fit-content;
	height: fit-content;
	font-size: 10px;
	padding: 1px 5px;
	margin: -8px -8px 0 0;
}

.vendor_signature_Tom_signature {
	color: #510f30;
	font-weight: 600;
}

.tom_signature {
	color: #510F30;
	position: relative;
	right: 5px;
	font-weight: 600;
}

/* .MuiDialog-paperScrollPaper {
	border-radius: 0px 0px 25px 25px !important;
  } */
.MuiTypography-root .contatct_approve_head .MuiTypography-h6 {
	font-family: 'Roboto', sans-serif !important;
	font-weight: 400 !important;
}

.approval_contract_modal .MuiDialog-paperScrollPaper {
	/* background-color:red !important; */
	/* border-radius: 0px 0px 43px 43px !important; */
}


.alert_success_popup.Dialogmodal .MuiDialogTitle-root {
	/* text-align: center; */
	display: flex;
	/* box-shadow: 0 0 3.5px 0 #ccc; */
	/* border-bottom: 1px solid #ccc; */
	background-color: white;
	justify-content: center;
	box-shadow: none;
	border-bottom: 0px !important;
	margin-bottom: 11px !important;
}

.Dialogmodal h6.contatct_approve_head {
	color: #510F30 !important;
}

.Dialogmodal h6.contatct_approve_head.approval_details {
	color: white !important;
	margin-bottom: 5px;
}

.signature-pad {
	width: 300px !important;
}

.m-signature-pad--body {
	width: 300px !important;
}

.MuiStepConnector-alternativeLabel {
	top: 18px !important;
	left: calc(-50% + 20px);
	right: calc(50% + 20px);
	position: absolute;
}

.closemodal_icon {
	color: #ff0068 !important;
	font-size: 20px !important;
}

.pharmacy_textData {
	/* height: 100vh; */
	position: fixed;
	top: 46%;
	left: -3%;
	/* right: 79%; */
	align-items: start;
	/* justify-content: center; */
	font-size: 4rem;
	color: white;
	/* margin: auto; */
	font-family: "Avenir-Black" !important;
	transform: rotate(-90deg);
	/* right: 1200px; */
	text-shadow: 0px 3px 6px #000000e6;
}

.media_delete_popup .MuiDialog-paperFullWidth {
	min-width: 25% !important;
	max-width: 26% !important;
	width: auto !important;
}


.pushNotification_body {
	/* display: flex;
	position: absolute;
	top: 24px;
	left: calc(100vw - 67.7%);
	background-color: #fff;
	box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
	padding: 10px;
	width: 400px;
	z-index: 9999999;
	margin: auto; */

	display: flex;
	position: absolute;
	top: 24px;
	left: calc(100vw - 70%);
	background-color: #fff;
	box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
	padding: 10px;
	width: 520px;
	z-index: 9999999;
	margin: auto;
	border-radius: 8px;
}

.pushNotification_body .logo {
	width: 120px;
	height: auto;

}

.pushNotification_body .logo img {
	width: 100%;
}

.pushNotification_body .pushNotification_content {
	text-align: left;
	padding: 0px 0px 0px 15px !important;
	font-size: 16px;
}

.pushNotification_content {
	text-align: left;
	padding: 14px;
	font-size: 16px;
}


  .terms_and_policies1 {
	display: flex;
	justify-content: center;
	margin-top: 25px;
}
.notification_container {
	width: 350px;
	display: flex;
	max-width: auto;
}

.notification_container .logo {
	width: 50px;
	height: 50px;
	margin: 10px 10px 10px 0px;
	margin-left: 5%;
}

.notification_container .logo img {
	width: -webkit-fill-available;
	object-fit: contain;
}

.notification_container .content p {
	color: #000000;
	margin: 2px 0px;
	font-size: 13px;
	margin-left: 3vh;
}

.content_body {
	font-size: 14px !important;
}

.content_head {
	font-size: 16px !important;
}

.sign_err {
	color: #ff0068;
	display: flex;
	justify-content: center;
	position: relative;
	top: 3px;
}