.daterangeMaster {
    z-index: 1;
    position: absolute;
}

.daterangeMaster .rdrDateRangePickerWrapper {
    border: 1px solid #c3bbbb;
    border-radius: 3px;
}

.daterangeMaster .rdrDefinedRangesWrapper, .daterangeMaster .rdrDateRangeWrapper {
    border-radius: 3px;
}

.daterangeMaster .rdrDefinedRangesWrapper {
    border: 1px solid #c3bbbb;
    border-left: none;
    background-color: #eff2f7;
}

.daterangeMaster .rdrDefinedRangesWrapper button {
    background-color: inherit;
    /* color: white; */
    /* border-bottom: 1px solid; */
}

/* .daterangeMaster .rdrCalendarWrapper {
    border: 1px solid #c3bbbb;
    border-radius: 5px;
}

.daterangeMaster .rdrStartEdge,
.daterangeMaster .rdrInRange,
.daterangeMaster .rdrEndEdge{
    color: var(--app-theme) !important;
}


.daterangeMaster .rdrDayStartPreview,
.daterangeMaster .rdrDayInPreview,
.daterangeMaster .rdrDayEndPreview {
    color: var(--app-theme)!important;
}

.daterangeMaster .rdrWeekDay{
    color: var(--app-theme);
} */

/* Range Picker Common Css*/

.rangePicker_Master {
    /* margin: 10px 0px;
    height: 50px;
    border: 1px solid #e4dede; */
    display: flex;
    align-items: center;
    margin-right: 45px;
}

.rangePicker_Label {
    font-size: 18px;
    margin-left: 30px;
    margin-right: 10px;
    font-family: titillium web !important;
    color: black;
    font-weight: 500;
}

.rangePicker_Master .formdiv {
    /* width: 122% !important; */
    margin-top: 20px;
    height: 51.5px;
}
.daterange_calendar{
    position: relative;
}

.rangePicker_Master .brdrcls {
    height: 30px;
    width: 107% !important;
    /* padding: 4px 43px 4px 15px!important; */
    font-size: 13px!important;
    font-weight: 500!important;
    /* text-overflow: ellipsis; */
    margin-top: 2px;
    margin-right: 50px;
}

.rangePicker_Master input::placeholder {
    /* Chrome/Opera/Safari */
    color: #ccc;
}

.rangePicker_Master input {
    cursor: pointer;
}

.rangePicker_Calendericon {
    /* z-index: 9999999; */
    color: grey;
    position: relative;
    right: 7%;
    margin-top: 1%;
}

.rangePicker_Calendericon:hover {
    background-color: #e6dfdf;
    border-radius: 10px;
    cursor: pointer;
}

.rangePicker_InpputIconAlign {
    display: flex;
    align-items: center;
}

.rangePicker_cancelbtn {
    font-size: 12px !important;
    background-color: #b3b3b3 !important;
    color: #333 !important;
    margin-right: 20px !important;
    border-radius: 4px !important;
    height: 25px;
}

.rangePicker_okbtn {
    background-color: var(--app-theme) !important;
    color: #fff !important;
    font-size: 12px !important;
    border-radius: 4px !important;
    height: 25px;
    margin-right: 20px !important;
}

.rangePicker_btncontainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border: 1px solid #c3bbbb;
    height: 35px;
    background-color: #eff2f7;
    border-top: none;
}

.daterangeMaster .rdrMonthAndYearWrapper {
    height: 35px;
    padding-top: 0px;
}

.daterangeMaster .rdrDefinedRangesWrapper {
    width: 185px;
    border-bottom: none;
    border-top: none;
    font-size: 13px;
    font-weight: 400;
}

.daterangeMaster .rdrCalendarWrapper {
    font-size: 13px;
}

.rangePicker_positionCenter {
    position: relative;
    z-index: 1300;
    right: 0px;
    bottom: 0px;
    top: 0px;
    left: 0px;
}

@media screen and (max-width: 883px) {
    .daterangeMaster .rdrCalendarWrapper {
        font-size: 9px;
    }
}

.range_picker_reduce .rangePicker_Master {
    margin-right: 25px !important;
}

.arabic_cls .rangePicker_Master {
    direction: rtl;
    margin-left: 25px !important;
    margin-right: 0px !important;
}

.arabic_cls .rangePicker_Label{
    margin-left: 10px !important;
    margin-right: 0px !important; 
}