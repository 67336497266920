.cell_eye {
  padding-left: 0px !important;
}
.nurse_book_table_div table tbody tr th:nth-child(1){
  padding-right: 20px !important;
  text-align: center !important;
}

.nurse_book_table_div table thead tr th:nth-child(1),

.nurse_book_table_div table thead tr th:nth-child(4),
.nurse_book_table_div table tbody tr td:nth-child(4),
.nurse_book_table_div table thead tr th:nth-child(5),
.nurse_book_table_div table tbody tr td:nth-child(5),
.nurse_book_table_div table thead tr th:nth-child(3),
.nurse_book_table_div table tbody tr td:nth-child(3),
.nurse_book_table_div table thead tr th:nth-child(7),
.nurse_book_table_div table tbody tr td:nth-child(7),
.nurse_book_table_div table thead tr th:nth-child(8),
.nurse_book_table_div table tbody tr td:nth-child(8),
.nurse_book_table_div table thead tr th:nth-child(9),
.nurse_book_table_div table tbody tr td:nth-child(9),
.nurse_book_table_div table thead tr th:nth-child(10),
.nurse_book_table_div table tbody tr td:nth-child(10)

{
  text-align: center;
  justify-content: center !important;
}



.arabic_cls .nurse_book_table_div table thead tr th:nth-child(7),
.arabic_cls .nurse_book_table_div table tbody tr td:nth-child(7),
.arabic_cls .nurse_book_table_div table thead tr th:nth-child(5),
.arabic_cls .nurse_book_table_div table tbody tr td:nth-child(5),
.arabic_cls .nurse_book_table_div table thead tr th:nth-child(4),
.arabic_cls .nurse_book_table_div table tbody tr td:nth-child(4),
.arabic_cls .nurse_book_table_div table thead tr th:nth-child(3),
.arabic_cls .nurse_book_table_div table tbody tr td:nth-child(3),
.arabic_cls .nurse_book_table_div table thead tr th:nth-child(2),
.arabic_cls .nurse_book_table_div table tbody tr td:nth-child(2),
.arabic_cls .nurse_book_table_div table thead tr th:nth-child(1),
.arabic_cls .nurse_book_table_div table tbody tr td:nth-child(1)
{
  text-align: center !important;
  justify-content: center !important;
}

.arabic_cls .nurse_book_table_div table thead tr th:nth-child(6),
.arabic_cls .nurse_book_table_div table tbody tr td:nth-child(6),
.arabic_cls .nurse_book_table_div table thead tr th:nth-child(10),
.arabic_cls .nurse_book_table_div table tbody tr td:nth-child(10)
{
  text-align: right;
}

.arabic_cls .ant-input-affix-wrapper .ant-input-suffix {
  right: unset;
  left: 12px;
}
.arabic_cls .ant-input-affix-wrapper .ant-input:not(:last-child){
  padding-right: 10px;
  padding-left: 30px;
}