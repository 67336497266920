.cell_eye {
  padding-left: 0px !important;
}


.nurse_on_leave_table_div table thead tr th:nth-child(1),
.nurse_on_leave_table_div table tbody tr th:nth-child(1),
.nurse_on_leave_table_div table thead tr th:nth-child(4),
.nurse_on_leave_table_div table tbody tr td:nth-child(4),
.nurse_on_leave_table_div table thead tr th:nth-child(5),
.nurse_on_leave_table_div table tbody tr td:nth-child(5),
.nurse_on_leave_table_div table thead tr th:nth-child(6),
.nurse_on_leave_table_div table tbody tr td:nth-child(6),
.nurse_on_leave_table_div table thead tr th:nth-child(7),
.nurse_on_leave_table_div table tbody tr td:nth-child(7),
.nurse_on_leave_table_div table thead tr th:nth-child(8),
.nurse_on_leave_table_div table tbody tr td:nth-child(8),
.nurse_on_leave_table_div table thead tr th:nth-child(9),
.nurse_on_leave_table_div table tbody tr td:nth-child(9)
{
  text-align: center;
  justify-content: center !important;
}


.arabic_cls .nurse_on_leave_table_div table thead tr th:nth-child(9),
.arabic_cls .nurse_on_leave_table_div table tbody tr th:nth-child(9),
.arabic_cls .nurse_on_leave_table_div table thead tr th:nth-child(6),
.arabic_cls .nurse_on_leave_table_div table tbody tr td:nth-child(6),
.arabic_cls .nurse_on_leave_table_div table thead tr th:nth-child(5),
.arabic_cls .nurse_on_leave_table_div table tbody tr td:nth-child(5),
.arabic_cls .nurse_on_leave_table_div table thead tr th:nth-child(4),
.arabic_cls .nurse_on_leave_table_div table tbody tr td:nth-child(4),
.arabic_cls .nurse_on_leave_table_div table thead tr th:nth-child(3),
.arabic_cls .nurse_on_leave_table_div table tbody tr td:nth-child(3),
.arabic_cls .nurse_on_leave_table_div table thead tr th:nth-child(2),
.arabic_cls .nurse_on_leave_table_div table tbody tr td:nth-child(2),
.arabic_cls .nurse_on_leave_table_div table thead tr th:nth-child(1),
.arabic_cls .nurse_on_leave_table_div table tbody tr td:nth-child(1)
{
  text-align: center;
}

.arabic_cls .nurse_on_leave_table_div table thead tr th:nth-child(7),
.arabic_cls .nurse_on_leave_table_div table tbody tr td:nth-child(7),
.arabic_cls .nurse_on_leave_table_div table thead tr th:nth-child(8),
.arabic_cls .nurse_on_leave_table_div table tbody tr td:nth-child(8)
{
  text-align: right;
}