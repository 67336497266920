.deal_list_paper_maincontainer {
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 21px 0px 12px;
  padding-right: 8px;
  overflow-y: scroll;
  height: 370px;
}

.deal_list_paper_maincontainer .MuiGrid-root.MuiGrid-container {
  height: 368px;
}
.KWD_font{
  font-size: 10px !important;
}

.date_view {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  font-size: 1.5rem !important;
}

.list_test_report {
  color: #333;
  font-size: 1.5rem !important;
}

.view {
  font-size: 1.5rem !important;
  color: var(--app-theme) !important;
}

.iconsdiv {
  font-size: 2rem !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  margin-left: 49%;
}

.iconsdiv img,
.iconsdiv svg {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.edit_icon_div {
  fill: var(--app-theme) !important;
}

.delete_icon_div {
  fill: #d11a2a !important;
}

.deal_paper {
  padding: 10px;
}

.listTitleWidth {
  width: 30.5%;
}

.aligndeallistdata {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.aligndeallistdata div span {
  color: #4a4a4a;
}

.aligndeallistdata div div {
  color: #989898;
  font-size: 13.7px;
}

.aligndeallistdataRow2 {
  display: flex;
  padding: 0px 10px 0px 10px;
}

.aligndeallistdataRow2 div span {
  color: #4a4a4a;
}

.aligndeallistdataRow2 div div {
  color: #989898;
  font-size: 13.7px;
}

.dyndeallistPaper {
  margin-bottom: 10px;
  margin-left: 4px;
}

.deal_list_paper_maincontainer .MuiPaper-elevation1 {
  border: 1px solid #bbb8b8;
}

.titleDealFlex {
  display: flex;
  width: 41%;
}

.listTitleWidth {
  width: 74.5%;
}

.deal_left_content {
  font-size: 16px;
}

.deal_edit_book {
  background: var(--app-theme) 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 2px 5px #00000033 !important;
  border-radius: 50px !important;
  opacity: 1 !important;
  color: #fff !important;
  font-size: 16px !important;
  padding: 6px 16px !important;
  font-weight: 500 !important;
  font-family: "Roboto" !important;
  text-transform: capitalize !important;
}

.delete_book {
  background: #d11a2a 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 2px 5px #00000033 !important;
  border-radius: 2px !important;
  opacity: 1 !important;
  color: #fff !important;
  font-size: 13px !important;
  padding: 6px 16px !important;
  font-weight: 600 !important;
  font-family: "Roboto" !important;
}

.tablediv table th {
  position: sticky;
  top: 0px;
  background: white;
}

.deal_list{
  width: 100%;
}
.deal_list .mypagination {
  font-size: 16px;
  width: 80%;
  margin: 10px 0px 0px 0px;
  padding: 0;
  justify-content: start;
}


.list_edit {
  fill: white !important;
  margin-right: 5px;
}

.list_edit:hover {
  cursor: pointer;
}

.listdelete_icon {
  fill: white !important;
  margin-right: 5px;
  cursor: pointer;
}

.deal_repet_book{
    background: var(--app-theme)  0% 0% no-repeat padding-box !important;
    box-shadow: 0px 2px 5px #00000033 !important;
    border-radius: 50px !important;
    opacity: 1 !important;
    color: #fff !important;
    font-size: 16px !important;
    padding: 6px 16px !important;
    font-weight: 500 !important;
    font-family: "Roboto" !important;
    text-transform: capitalize !important;
}

.deal_data_div label
{
  font-size: 16px !important;
  color: black !important;
  font-weight: 500 !important;
}

.deal_data_div span
{
  /* font-size: 14px !important; */
  color: #707070 !important;
}