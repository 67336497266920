.upload_ins{
    font-size: 1.5rem;
    margin-left: 10px;
    position: relative;
    top: 3px;
}
.green_upload_container{
    margin-bottom: 15px;
}
.clrchange{
    color:red !important;
}

.upload_ins_popup .MuiDialog-paperFullWidth
{
    min-width: 20%;
    max-width: 30% !important;
    height: 35% !important;
}

.upload_ins_popup .Dialogmodal span{
    font-family: titillium web !important;
}
.green_uploadint
{
  height: 14px;
  width: 14px;
  background-color: var(--pink-color);
  border-radius: 100%;
  margin-top: 5px;
}