.cell_eye {
  padding-left: 0px !important;
}

.nurse_dash_sidehead {
  font-family: "Roboto" !important;
  margin-top: 20px;
  margin-left: 20px;
  font-size: 16px;
  color: black;
  font-weight: 400;
}

.page_button_container {
  padding-bottom: 10px
}

.lab_dash_bottom_buttons {
  margin-left: 20px !important;
  font-size: 1rem !important;
  box-shadow: 11px 23px 16px -30px black;
}

.lab_dash_bottom1 {
  color: #ec3a32 !important;
  border: 1px solid !important;
  font-size: 1.75rem !important;
  box-shadow: 0px 0px 2px #ec3a32;
  text-transform: none !important;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04), 0 4px 8px 0 rgba(0, 0, 0, 0.2) !important;
}

.lab_dash_bottom1 .MuiButton-label {
  padding: 0px 18px;
  font-size: 18px;
}

.lab_dash_bottom2 {
  color: #eb1e68 !important;
  border: 1px solid !important;
  font-size: 1.75rem !important;
  box-shadow: 0px 0px 2px #eb1e68;
  text-transform: none !important;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04), 0 4px 8px 0 rgba(0, 0, 0, 0.2) !important;
}

.lab_dash_bottom2 .MuiButton-label {
  padding: 0px 18px;
  font-size: 18px;
}

.lab_dash_bottom3 {
  color: #a42ab8 !important;
  border: 1px solid !important;
  font-size: 1.75rem !important;
  box-shadow: 0px 0px 2px #a42ab8;
  text-transform: none !important;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04), 0 4px 8px 0 rgba(0, 0, 0, 0.2) !important;
}

.lab_dash_bottom3 .MuiButton-label {
  padding: 0px 18px;
  font-size: 18px;
}

.todays_appointments_text {
  font-weight: 500;
  font-size: 16px;
  margin-right: 5px;
  color: black;
}

@media only screen and (max-width: 1040px) {
  .lab_dash_bottom3 {
    font-size: 1.25rem !important;
  }

  .lab_dash_bottom1 {
    font-size: 1.25rem !important;
  }

  .lab_dash_bottom2 {
    font-size: 1.25rem !important;
  }
}

.nurse_dashboard_table_div table thead tr th:nth-child(1),
.nurse_dashboard_table_div table tbody tr th:nth-child(1),
.nurse_dashboard_table_div table thead tr th:nth-child(4),
.nurse_dashboard_table_div table tbody tr td:nth-child(4),
.nurse_dashboard_table_div table thead tr th:nth-child(5),
.nurse_dashboard_table_div table tbody tr td:nth-child(5),
.nurse_dashboard_table_div table thead tr th:nth-child(6),
.nurse_dashboard_table_div table tbody tr td:nth-child(6),
.nurse_dashboard_table_div table tbody tr td:nth-child(7),
.nurse_dashboard_table_div table thead tr th:nth-child(7),
.nurse_dashboard_table_div table thead tr th:nth-child(8),
.nurse_dashboard_table_div table tbody tr td:nth-child(8),
.nurse_dashboard_table_div table thead tr th:nth-child(9),
.nurse_dashboard_table_div table tbody tr td:nth-child(9),
.nurse_dashboard_table_div table thead tr th:nth-child(10),
.nurse_dashboard_table_div table tbody tr td:nth-child(10),
.nurse_dashboard_table_div table thead tr th:nth-child(11),
.nurse_dashboard_table_div table tbody tr td:nth-child(11) {
  text-align: center;
}

.nurse_dashboard_table_div .table>thead:first-child>tr:first-child>th:nth-child(3){
  display: inline-flex;
}

.nurse_dashboard_table_div .table>tbody>tr>td:nth-child(3){
  text-align: center !important;
  padding-left: 0px;
}

.order_complete_legend {
  height: 15px;
  width: 15px;
  background: #00A49A 0% 0% no-repeat padding-box;
  ;
  margin-left: 10px;
  margin-top: 4px;
}

.order_starting_legend {
  height: 15px;
  width: 15px;
  background: #FF0068 0% 0% no-repeat padding-box;
  margin-left: 3rem;
  margin-top: 4px;
}


.arabic_cls .nurse_dashboard_table_div table thead tr th:nth-child(7),
.arabic_cls .nurse_dashboard_table_div table tbody tr td:nth-child(7),
.arabic_cls .nurse_dashboard_table_div table thead tr th:nth-child(6),
.arabic_cls .nurse_dashboard_table_div table tbody tr td:nth-child(6),
.arabic_cls .nurse_dashboard_table_div table thead tr th:nth-child(5),
.arabic_cls .nurse_dashboard_table_div table tbody tr td:nth-child(5),
.arabic_cls .nurse_dashboard_table_div table thead tr th:nth-child(4),
.arabic_cls .nurse_dashboard_table_div table tbody tr td:nth-child(4),
.arabic_cls .nurse_dashboard_table_div table thead tr th:nth-child(3),
.arabic_cls .nurse_dashboard_table_div table tbody tr td:nth-child(3),
.arabic_cls .nurse_dashboard_table_div table thead tr th:nth-child(2),
.arabic_cls .nurse_dashboard_table_div table tbody tr td:nth-child(2),
.arabic_cls .nurse_dashboard_table_div table thead tr th:nth-child(1),

.arabic_cls .nurse_dashboard_table_div table thead tr th:nth-child(10),
.arabic_cls .nurse_dashboard_table_div table tbody tr th:nth-child(10) {
  text-align: center !important;
}

.arabic_cls .nurse_dashboard_table_div table tbody tr td:nth-child(4) {
  padding-right: 10px;
}

.arabic_cls .nurse_dashboard_table_div table tbody tr td:nth-child(1) {
  text-align: center !important;
  justify-content: center !important;
}

.arabic_cls .nurse_dashboard_table_div table thead tr th:nth-child(8),
.arabic_cls .nurse_dashboard_table_div table tbody tr td:nth-child(8),
.arabic_cls .nurse_dashboard_table_div table thead tr th:nth-child(9),
.arabic_cls .nurse_dashboard_table_div table tbody tr td:nth-child(9) {
  text-align: right;
}

.arabic_cls .todays_appointments_text {
  float: left;
}

.todaywwe {
  direction: rtl;
  display: flex;
  margin-right: 19px;
}

.todayDate {
  direction: ltr;
}

.ar_todaydate {
  margin-right: 5px;
}

.walkIn .formdiv .selectbox .ant-select-selection--single{
  width: 100%;
  max-height: 35px !important;
}

.walkIn .formdiv .ant-select-selection__rendered {
  line-height: 26px !important;
}