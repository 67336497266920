.cell_eye {
  padding-left: 0px !important;
}

.completed_book_table_div table thead tr th:nth-child(1),
.completed_book_table_div table tbody tr th:nth-child(1),
.completed_book_table_div table thead tr th:nth-child(4),
.completed_book_table_div table tbody tr td:nth-child(4),
.completed_book_table_div table thead tr th:nth-child(3),
.completed_book_table_div table tbody tr td:nth-child(3),
.completed_book_table_div table thead tr th:nth-child(6),
.completed_book_table_div table tbody tr td:nth-child(6),
.completed_book_table_div table thead tr th:nth-child(7),
.completed_book_table_div table tbody tr td:nth-child(7),
.completed_book_table_div table thead tr th:nth-child(8),
.completed_book_table_div table tbody tr td:nth-child(8),
.completed_book_table_div table thead tr th:nth-child(9),
.completed_book_table_div table tbody tr td:nth-child(9),
.completed_book_table_div table thead tr th:nth-child(10),
.completed_book_table_div table tbody tr td:nth-child(10)

{
  text-align: center;
  justify-content: center !important;
}

.arabic_cls .completed_book_table_div table thead tr th:nth-child(8),
.arabic_cls .completed_book_table_div table tbody tr td:nth-child(8),
.arabic_cls .completed_book_table_div table thead tr th:nth-child(7),
.arabic_cls .completed_book_table_div table tbody tr td:nth-child(7),
.arabic_cls .completed_book_table_div table thead tr th:nth-child(5),
.arabic_cls .completed_book_table_div table tbody tr td:nth-child(5),
.arabic_cls .completed_book_table_div table thead tr th:nth-child(4),
.arabic_cls .completed_book_table_div table tbody tr td:nth-child(4),
.arabic_cls .completed_book_table_div table thead tr th:nth-child(3),
.arabic_cls .completed_book_table_div table tbody tr td:nth-child(3),
.arabic_cls .completed_book_table_div table thead tr th:nth-child(2),
.arabic_cls .completed_book_table_div table tbody tr td:nth-child(2),
.arabic_cls .completed_book_table_div table thead tr th:nth-child(1),
.arabic_cls .completed_book_table_div table tbody tr td:nth-child(1)
{
  text-align: center !important;
}

.arabic_cls .completed_book_table_div table thead tr th:nth-child(6),
.arabic_cls .completed_book_table_div table tbody tr td:nth-child(6),
.arabic_cls .completed_book_table_div table thead tr th:nth-child(9),
.arabic_cls .completed_book_table_div table tbody tr td:nth-child(9)
{
  text-align: right;
}