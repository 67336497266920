.ticketmaster .doctorplus-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ticketmaster .plus-icon {
  cursor: pointer;
  margin-left: 30px;
}

.ticketmaster .currentdate {
  font-size: 1.5rem;
  margin-right: 13px;
}

.uploadsmasterheader {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  padding: 5px 15px;
}

.ticket_managetitleuser {
  width: 40%;
  font-size: 22px;
  color: var(--app-theme);
  font-weight: 500;
  font-family: titillium web !important;
}

.current_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

._2fG9h {
  padding: 0px 0px 40px 0px !important;
}

._dt3-T {
  min-height: 300px !important;
}

._1Yplu {
  top: calc(100% + 5px);
  position: absolute;
  left: calc(100% - 150px);
  margin: 0px !important;
}

.success_tick_mark {
  height: 80px;
  width: 80px;
  background-repeat: no-repeat;
}

.ticket_add_div .formdiv .labeltxt,
.ticket_view_div .formdiv .labeltxt {
  font-size: 16px !important;
  color: black !important;
}

.arabic_cls .ticket_add_div .formdiv .labeltxt,
.ticket_view_div .formdiv .labeltxt {
  font-size: 16px !important;
  color: black !important;
  text-align: start !important;
}


.ticket_add_div .formdiv .brdrcls,
.ticket_add_div .ant-select-selector,
.ticket_view_div .formdiv .brdrcls {
  font-size: 14px !important;
  color: #707070 !important;
}

.response_main_div {
  width: 100%;
  padding: 4px;
  border: none;
  outline: 0;
  font-size: 14px;
  box-shadow: 0 0 1.5px 0px #ccc;
  border: 1px solid #ccc;
  border-radius: 2px !important;
  color: #707070;
  min-height: 75px;
}

.response_main_div div {
  display: table;
  border-radius: 50px;
  background-color: lightgray;
  padding: 8px;
  margin-bottom: 10px;
}

.arabic_cls .current_container {
  justify-content: flex-start;
}

.arabic_cls .ticketmaster .plus-icon {
  margin: 0px;
}

.ar_align {
  text-align: right;
}

.ticket_add_div .formdiv .selectbox {
  width: 100% !important;
}