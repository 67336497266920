
.avail_button {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
  /* padding: 40px 105px; */
  position: fixed;
  top: 50%;
  left: 75.5%;
}

.avail_button .btn-lg {
  width: 120px;
  height: 40px;
  border-radius: initial;
}

.avail_button .leave_btn {
  background-color: var(--app-theme);
  border: none;
  margin-right: 6px;
  font-size: 16px;
  border-radius: 50px !important;
  height: 35px !important;
  width: 100px;
  color: white;
}

.avail_button .block_btn {
  background-color: #818181;
  border: none;
  font-size: 16px;
  border-radius: 50px !important;
}

/* .opacity_letter_availability .ant-select-selection-selected-value{
  opacity: 0.5 !important;
} */

.opacity_letter_availability div div {
  border-radius: 2px;
}

.errormsgAvailability{
  font-size: 12px;
  color: #ff0068;
  white-space: nowrap;
  position: relative;
    bottom: 10px;
}

.availability_main_div .formdiv .labeltxt
{
  color :Black;
  font-weight : 400 !important;
  font-size : 16px !important;
}

.availability_main_div .formdiv .MuiInputBase-input,.ant-select-selection__placeholder,.availability_main_div .ant-select-selection--single
{
  color : #707070 !important;
  font-weight : 400 !important;
  font-size :14px !important;
}
.user_datepicker {
  width: 100%;
  border: none;
  outline: 0;
  box-shadow: 0 0 1.5px 0px #ccc;
}
