.mypagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
    width: 80%;
    justify-content: flex-end;
  }
  .mypagination > .active > a{
    background-color: var(--app-theme) ;
    border-color: var(--app-theme) ;
    color: #fff;
  }

  .mypagination > li > a{
    /* border: 1px solid orange ; */
    padding: .7rem 1.5rem;
    outline: none;
    cursor: pointer;
    
  }
  .mypagination > .active > a, .mypagination > .active > span, .mypagination > .active > a:hover, .mypagination > .active > span:hover, .mypagination > .active > a:focus, .mypagination > .active > span:focus{
    background-color: var(--app-theme) ;
    border-color: var(--app-theme);
    outline: none ;
    
  }
  .mypagination > li > a, .mypagination > li > span{
    color: var(--app-theme);
    background: #fff;
    
  }
  .mypagination > li:first-child > a, .mypagination > li:first-child > span, .mypagination > li:last-child > a, .mypagination > li:last-child > span{
    border-radius: unset
  }



  .pagination__box .mypagination .previous a {
    background-color: var(--app-theme);
    color:#fff;
    padding: .7rem 1.5rem;
    margin-right: .9rem;
  }

  .pagination__box .mypagination .next a {
    background-color: var(--app-theme);
    color:#fff;
    margin-left: 2rem;
    padding: .7rem 1.5rem;
  }

