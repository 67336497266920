.VendorDetailsDiv table {
  border-collapse: separate;
  border-spacing: 0 5px;
  background-color: transparent;
  padding: 15px;
}

.VendorDetailsDiv tr {
  /*box-shadow: 0 0 0.5px #000;*/
  background: white;
}

.VendorDetailsDiv thead tr {
  background: transparent !important;
}

.VendorDetailsDiv tr,
.VendorDetailsDiv th,
.VendorDetailsDiv td {
  border-bottom: 0px solid rgba(224, 224, 224, 1);
}

.VendorDetailsDiv .addemployeebtn {
  text-align: left;
  padding-bottom: 20px;
}

.VendorDetailsDiv .addemployeetxt {
  background: white;
  width: 177px;
  padding: 8px;
  justify-content: space-between;
  box-shadow: 1px 1px 1px 1px #ccc;
  display: inline-flex;
  align-items: center;
}

.VendorDetailsDiv .employeeTitle {
  padding-left: 15px;

  font-size: 25px;
  color: #333;
}

/* .VendorDetailsDiv .Mui-selected,
  .VendorDetailsDiv tbody tr:hover {
    background-color: white !important;
    box-shadow: 0px 0 10.5px #ddd;
  } */
.VendorDetailsDiv tbody th,
.VendorDetailsDiv tbody td {
  /* color: #b5abab; */
  color: #707070;
  font-weight: 400;
  font-size: 14px;
  padding-top: 15px !important;
  text-transform: capitalize;
}

.Mui-selected th,
.Mui-selected td {
  color: #000 !important;
}

.VendorDetailsDiv .addemployeetxticon {
  padding-left: 10px;
  color: green;
  font-size: 22px;
  /* padding-top: 14px; */
  color: green;
  /* margin-top: 20px; */
  /* padding-top: 12px; */
  display: inline-flex;
}

.VendorDetailsDiv table thead tr th,
.VendorDetailsDiv table tbody tr th,
.VendorDetailsDiv table tbody tr td {
  padding-left: 15px;
  text-align: left;
  justify-content: center !important;
}
.arabic_cls .VendorDetailsDiv table tbody tr td{
    padding-left: 0px !important;
    text-align: left;
    justify-content: end !important;
}
.arabic_cls .manage_nurse_table_div .VendorDetailsDiv table tbody tr td{
    padding-left: 15px;
    text-align: left;
    justify-content: center !important;
}
.arabic_cls .completed_book_table_div  .VendorDetailsDiv table tbody tr td{
    padding-left: 15px;
    text-align: left;
    justify-content: center !important;
}

.arabic_cls .revenue-master-table .VendorDetailsDiv table tbody tr td{
    padding-left: 15px;
    text-align: left;
    justify-content: right !important;
}
/* .VendorDetailsDiv td:nth-child(10)
.VendorDetailsDiv td:nth-child(5){
  text-align: center;
} */
/*sear css */
.VendorDetailsDiv .searchicon {
  margin-right: 4% !important;
  padding-top: 2%;
}

.VendorDetailsDiv .searchicontxt {
  color: var(--app-theme);
  font-size: 24px;
}
.arabic_cls  .tableeye_icon {
  color: #5377ee;
  font-size: 20px !important;
  /* margin-right: 9px;   */
  cursor: pointer;
  height: 25px !important;
  float: right;
}

.VendorDetailsDiv .paper {
  width: "100%";
  margin-bottom: 10px;
  background-color: "transparent";
}

.VendorDetailsDiv table {
  min-width: 750;
}

.VendorDetailsDiv .tableWrapper {
  overflow-x: auto;
  margin-top: 5px;
  min-height: 375px;
}

.VendorDetailsDiv thead tr,
.VendorDetailsDiv thead th {
  font-size: 16px;
  font-weight: 500;
  color: black;
}

.VendorDetailsDiv .cancelbtn {
  background: darkgrey;
  margin-right: 2%;
  text-transform: capitalize;
  color: #000;
  width: 100px;
}

.VendorDetailsDiv .savebtn {
  background: #82af3f;
  text-transform: capitalize;
  color: #fff;
  width: 100px;
}

.VendorDetailsDiv tbody tr {
  box-shadow: 0 0 0.5px #9e9e9e;
  background: white;
}

.tablelocation_icon {
  color: #00e1d2;
  font-size: 20px !important;
  margin-right: 7px;
  cursor: pointer;
}

.tablehistory_icon {
  color: #000000;
  font-size: 20px !important;
  margin-right: 4px;
  cursor: pointer;

}

.VendorDetailsDiv tr,
.VendorDetailsDiv th,
.VendorDetailsDiv td {
  border-bottom: 0px solid rgba(224, 224, 224, 1);
}

.MuiTablePagination-select {
  margin-top: 10px;
  padding-left: 8px !important;
  padding-right: 24px !important;
  z-index: 0 !important;
}

.MuiTablePagination-caption {
  margin-top: 10px;

  font-size: 1.5rem !important;
}

/* .VendorDetailsDiv .MuiInputBase-input {
  padding-bottom: 10px !important;
} */

.MuiInputBase-input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  z-index: 300;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
}


.table thead th {
  border-bottom: 0px !important;
}

.VendorDetailsDiv tbody tr {
  /* box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12) !important; */
  box-shadow: 0px 0px 0px 0.5px #c3c3c399;
  height: 50px;
  border-radius: 2px !important;
}

.VendorDetailsDiv tbody tr:hover {
  background-color: white !important;
  box-shadow: 5px 5px 5px 0px #e0e0e0, inset 4px 4px 15px 0px #e4e4e4,
    -1px 2px 0px 2px rgba(224, 224, 224, 0);
  border-radius: 2px !important;
}

.VendorDetailsDiv thead tr {
  border-bottom: 0px !important;
  font-weight: bold !important;
}

.table>tbody>tr>th:first-child {
  /* padding-left: 28px; */
  width: 100px;
}

/* .table>tbody>tr>td:nth-child(2),
.table>tbody>tr>td:nth-child(3),
.table>tbody>tr>td:nth-child(4),
.table>tbody>tr>td:nth-child(5),
.table>tbody>tr>td:nth-child(6)
{
  width : 200px !important;
  
} */

/* width: unset !important; */
/* .table>tbody>tr>td:nth-child(2) {
  width : 200px !important;
}

.table>tbody>tr>td:nth-child(3) {
  width : 200px !important;
}

.table>tbody>tr>td:nth-child(4) {
  width : 200px !important;
}

.table>tbody>tr>td:nth-child(5) {
  width : 200px !important;
}

.table>tbody>tr>td:nth-child(6) {
  width : 200px !important;
} */
/* .table>tbody>tr>td:nth-child(7) {
  width : 180px !important;
} */

/* .table>tbody>tr>td:nth-child(7) {
  text-align: center !important;
} */

.table td,
.table th {
  border-top: 0px solid #dee2e6 !important;
}




.tableeye_icon {
  /* height: 20px !important; */
  height: 25px !important;
  /* margin-right: 13px; */
  cursor: pointer;
}

.tableedit_icon {
  color: var(--app-theme);
  font-size: 20px !important;
  margin-right: 4px;
  cursor: pointer;
}

.tabledelete_icon {
  color: var(--pink-color);
  font-size: 20px !important;
  cursor: pointer;
  /* margin-left: 7px; */
}






/* .tableeye_icon {
  height: 25px !important;
  margin-right: 7px;
  cursor: pointer;
  margin-left: 4px; 
}

.tableedit_icon {
  color: var(--app-theme);
  font-size: 20px !important;
  margin-right: 7px;
  cursor: pointer;
}

.tabledelete_icon {
  color: var(--pink-color);
  font-size: 20px !important;
  cursor: pointer;
} */

.tableupload_icon {
  color: var(--app-theme);
  font-size: 20px !important;
  margin-right: 7px;
  cursor: pointer;
}

.tableupload_icon svg {
  width: 18px;
  height: 18px;
  fill: var(--app-theme);
}

.tableupload_icon {
  padding-top: 3px;
}

.group_grandtotal {
  display: flex;
  align-items: flex-end;
  border: 1px solid;
  background-color: var(--app-theme);
  font-size: 18px;
  padding: 15px;
  width: 235px;
  color: white;
  float: right;
  margin-top: 50px;
  margin-right: 15px;
}

.spinner_align {
  left: -42px !important;
}

.spinner_align span i {
  background-color: var(--app-theme);
}

.noFoundIconCenter {
  text-align: center !important;
}

.noFoundIconCenter img {
  width: 100px;
  margin-top: 40px;
  margin-right: 100px;
}

.arabic_cls .noFoundIconCenter img {
  width: 100px;
  margin-top: 40px;
  margin-right: 0px;
}

.noFoundIconCenter div {
  margin-right: 100px;
}

.arabic_cls .noFoundIconCenter div {
  margin-right: 0px;
}

.VendorDetailsDiv .noFoundIconCenter {
  border: 1px solid #e2dddd !important;
  padding-bottom: 85px;
}

.modeOfPayment {
  display: flex;
  justify-content: center;
  position: relative;
  top: 15px;
  font-size: 14px;
  font-weight: 600;
  color: #333;
}

.modeOfPaymentText {
  border-bottom: 1px solid black;
  width: 270px;
  justify-content: center;
  display: flex;
  align-items: center;
  position: relative;
  left: 336px;
}


.ticket_reopen {
  border: 1px solid black;
  padding: 5px 10px;
  margin: 0px 5px 0px 5px;
  border-radius: 13px;
  cursor: pointer;
  background: white;
  color: #ff0068;
  border: 1px solid #ff0068;
}

.ticket_reopen:hover {
  background-color: #ff0068;
  border: 1px solid #ff0068;
  color: white;
}

.ticket_resolved {
  border: 1px solid black;
  padding: 5px 10px;
  margin: 0px 5px 0px 5px;
  border-radius: 13px;
  color: black;
  cursor: pointer;
}

.ticket_resolved:hover {
  background-color: var(--app-theme);
  border: 1px solid var(--app-theme);
  color: white;
}

.reopen_status {
  background-color: #ff0068 !important;
  border: 1px solid #ff0068 !important;
  color: white !important;
  pointer-events: none;
}

/* .ticket_reopen,.ticket_resolved{
border: 1px solid black;
padding: 5px 10px;
margin: 0px 5px 0px 5px;
border-radius: 13px;
/* color: black; */
/* cursor: pointer; */
/* }  */
/* .ticket_reopen:hover{
  background-color: #ff0068;
border: 1px solid #ff0068;
color: white;
}.ticket_resolved:hover */
/* {
background-color: var(--app-theme);
border: 1px solid var(--app-theme);
color: white;
} */
/* .reopen_status
{
background-color: #ff0068  !important;
border: 1px solid #ff0068  !important;
color: white !important;
pointer-events: none;
} */


/* language change css*/
.arabic_cls .MuiTablePagination-spacer {
  margin-top: 10px;

  flex: none !important;
}


/* .TablePagination .p{
 margin-bottom: unset !important;
} */



/* NurseRating.css */
.star-rating {
  font-size: 24px;
  display: inline-block;
}

.star-rating .current-rating {
  position: relative;
  top: -3px;
}

.star-rating .star {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 4px;
  background-color: #ccc;
  /* Set the background color of the stars */
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  -webkit-clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
}

.star-rating .star.full {
  background-color: #ffd700;
  /* Set the color of the filled stars */
}

.HeadingButton {
  background-color: #510f30;
  border-radius: 18px;
  color: white !important;
  padding: 7px 9px;
  font-size: 12px;
}

.HeadingButton2 {
  background-color: #ff0068;
  border-radius: 18px;
  color: white !important;
  padding: 7px 9px;
  font-size: 12px;
}


.heading_button_value {
  background-color: #510f30;
  border-radius: 12px;
  padding: 7px 8px;
}

/* <div class="demo">
  <div class="stars" style="--rating: 0.1">
    <div class="bg">⭐⭐⭐</div>
  </div> */