.printtabledata table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.printtabledata td, .printtabledata th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

.printtabledata{
    margin: 100px 80px 80px 80px;
}

.printDataTitle{
    font-size: 25px !important;
    margin-bottom: 20px;
    color:#510f30 !important;
    font-weight: 500;
}
.tom_logo_print{
    height:3%;
    width:10%;
    margin-bottom: 20px;
}
.print_header{
    background-color: #510f30 !important;
}
.print_header_text{
    color: white !important;
    font-size:17px !important;
}
.print_header_textss{
    font-size:14px !important;
}

