.confirm_password {
  background-color: #8bc137;
  border-radius: 50%;
  font-size: 2rem 1important;
  /* font-size: 25rem !important; */
  padding: 3px;
  position: relative;

  color: #fff;
  font-size: 2.5rem !important;
}
.success {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  padding: 20px;
  color: #595959;
  font-family: "Avenir-Black" !important;
}
.pharmacy_login_container .MuiInputBase-input {
  /* font-size: 15px !important;
  height: 3rem !important;
  font-family: "Avenir-Black" !important;
  padding: 6px 0 4px !important; */	
  font-size: 14px !important;
    height: 3rem !important;
    font-family: "Roboto" !important;
    padding: 6px 0 4px !important;
    font-weight: 400 !important;
  
}
a:focus, a:hover {
  /* color: #23527c !important; */
  /* text-decoration: underline; */
  text-decoration: none !important

}