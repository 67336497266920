.common_success_tick_mark {

  height: 80px;
  width: 80px;
  background-repeat: no-repeat;
}

.CommonSuccessSubmit {
  font-size: 16px !important;
  background-color: var(--app-theme) !important;
  color: #fff !important;
  text-transform: none !important;
  width: 80px;
  height: 4%;
  border-radius: 50px !important;
  padding: 5px 20px 5px 20px;
  border-color: var(--app-theme);
  margin-top: 2rem !important;
  font-weight: 500 !important;
}

.warning_icon {
  fill: #f45959 !important;
  height: 50px !important;
  width: 50px !important;
}

.notification_dialogue .MuiDialog-paperFullWidth {
  /* min-width: 20%;
  max-width: 40% !important;
  width: auto; */
  width: 310px;
  height: 284px;
}

.notification_dialogue .MuiPaper-rounded {
  border-radius: 30px;
}

.success_label {
  margin-top: 2rem;
  text-align: center;
  margin-bottom: 1rem;
  font-size: 20px;
  color: var(--app-theme);
  font-family: titillium web !important;
  font-weight: 400 !important;
}
.success_label1{
  margin-top: 2rem;
  text-align: center;
  margin-bottom: 1rem;
  font-size: 20px;
  color: #ff0068 !important;
  font-family: titillium web !important;
  font-weight: 400 !important;
}