.revenue-master-table323{
    td{
     padding: 15px 0 1px 0 !important;
     font-size: 14px !important;
    }
 }
  
  
  .card_container{
    padding: 25px 80px;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    color: white;
}
.revenue_card{
    background-color: #510f30;
    flex: 0 0 35%;
    padding: 15px;
    border-radius: 16px;
}
.revenue_card_active{
    box-shadow:rgb(81 15 48) 0px 5px 15px 0px;
}
.settle_card_active{
    box-shadow: rgb(255 0 104) 0px 5px 15px 0px;
}
.settle_card{
    background-color: #ff0068;
    flex: 0 0 35%;
    padding: 15px;
    border-radius: 16px;
}

.add_border{
    border-bottom: 1px dashed #ffffff9c;
}
table{ 
    width: 100%;
}
td{
    padding: 15px 0 1px 0;
    font-size: 14px;
}
.font_9{
    font-size: 9px;
}
.text-end{
    text-align: end!important;
}
.card_title{
    font-size: 14px;
    font-weight: 500;
    color: white;
}
.curr_value{
   text-align: end;
   font-size: 12px;
   color: #000;
   font-weight: 500;
   padding: 5px 20px;
}

/* toggler */

.toggler{
   width: 100%;
   display: inline-grid;
   align-items: center;
   justify-content: end;
   padding: 5px 15px;
 }.switches-container {
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    border-radius: 30px; 
    width: fit-content;
    position: relative;
    display: flex;
    padding: 0;
    position: relative;
    background: white;
    line-height: 26px;
    border-radius: 3rem;
    margin-left: auto;
    margin-right: auto;
}.switches-container input {
    visibility: hidden;
    position: absolute;
    top: 0;
}.switches-container label {
    width: fit-content;
    padding:1px 8px;
    margin: 0;
    text-align: center;
    cursor: pointer;
    color: #510f30;
    font-size: 14px;
}.switch-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    z-index: 3;
    transition: transform .5s cubic-bezier(.77, 0, .175, 1);
}.switch {
    border-radius: 3rem;
    background: #510f30;
    height: 100%;
    color: white !important;
}.switch div {
    width: 100%;
    text-align: center;
    opacity: 0;
    display: block;
    color: white;
    transition: opacity .2s cubic-bezier(.77, 0, .175, 1) .125s;
    will-change: opacity;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 12px;}

.dynaposition{
    right:20px;
}
.revenue_date{
    display: none !important;
}
/* .chart-container{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
} */
.nurse_nrew{
    padding: 15px 0 1px 0 !important;
    font-size: 14px !important;
}
