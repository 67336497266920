.notification_content_card {
    margin-top: 15px;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 2px !important;
    padding: 10px 20px;
}

.underline_status {
    border: 1px solid var(--app-theme);
    margin: 8px;
}

.notification_content {
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
    width: 100%;
}

.notification_content p {
    margin: 0;
}

.clinic_name {
    color: #8c6177;
}

.clinic_time {
    margin-top: 10px !important;
    color: #f7b79f;

}

.email_id {
    font-size: 14px;
    color: var(--app-theme);
    margin: 0px 10px;
}

.clinic_doctor_name {
    color: #f7b79f;
}

.notification_content .content {
    width: 100%;
    text-align: left;
}

.content_time {
    width: 20%;
    text-align: right;
    color: #ff0068;
    padding-right: 15px;
}

.d_flex {
    display: flex;
}

.sub_header {
    padding: 0 1rem;
    background-color: #ffffff;
    width: 100%;
    height: 10vh;
    position: fixed;
    top: 0px;
    left: 0px;
    border-bottom: 1px solid #dddddd;
    box-shadow: 0px 3px 6px #00000029;
    z-index: 999;
    overflow: hidden;
}

.sub_header .sub_header_body {
    display: flex;
    height: 10vh;
}

.sub_header .sub_header_body .back_navigation {
    cursor: pointer;
    height: -moz-fit-content;
    height: fit-content;
    margin: 15px 0px;
    display: flex;
    justify-content: center;
    align-items: end;
}

.sub_header .sub_header_body .back_navigation i {
    font-size: 25px;
    margin-top: -2px;
    color: #000000;
}

.sub_header .sub_header_body .back_navigation span {
    font-size: 16px;
    margin-left: 13px;
    color: #000000;
    margin-top: 2px;
}

.sub_header .sub_header_body .header_right_content {
    display: flex;
    align-items: flex-end;
}

.content_view {
    margin-top: 65px;
    padding: 0px 11px;
    height: calc(100vh - 80px);
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    overflow-y: auto;
}
@media (min-width: 1200px) {
    .content_view {
        margin-top: 80px; /* Slightly larger margin for large displays */
    }
}

/* Large screens (tablets in landscape or smaller desktops) */
@media (max-width: 1199px) and (min-width: 992px) {
    .content_view {
        margin-top: 70px; /* Medium margin for tablets in landscape */
    }
}

/* Medium screens (tablets in portrait) */
@media (max-width: 991px) and (min-width: 768px) {
    .content_view {
        margin-top: 60px; /* Reduced margin for tablets */
    }
}

/* Small screens (mobile devices) */
@media (max-width: 767px) {
    .content_view {
        margin-top: 50px; /* Smaller margin for mobile devices */
    }
}

/* Extra small screens (very small phones) */
@media (max-width: 480px) {
    .content_view {
        margin-top: 40px; /* Minimal margin for very small devices */
    }
}
.withI18nextTranslation\(MiniDrawer\)-toolbar-9 {
    box-shadow: none !important;
}