.dialog_nurse_eyeview .MuiDialog-paper {
  padding: 20px;
  overflow: scroll;
}

.nurse_view_image_childdiv {
  width: 82%;
  height: auto;
}

.nurse_view_image {
  width: 100%;
  /* box-shadow: 0px 0px 5px #333333a6; */
}

.nurse_view_contents_wrap {
  margin-left: 20px;
  font-family: "Roboto";
}

.nurse_view_wrap_container {
  display: flex;
  justify-content: space-between;
}

.nurse_view_name_wrap {
  margin-top: 15px;
  margin-left: 10px;
  position: relative;
  font-size: 1.5rem;
  font-family: "Roboto";
}

.nurse_view_cancelbutton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}

.dutytime_container {
  width: 16%;
  font-family: "Roboto";
}

.nurse_modelcancel {
  font-size: 1.5rem !important;
  color: #ff0000e0 !important;
  border: 1px solid #ff0000e0 !important;
  font-family: "Roboto" !important;
}

.generalduties_details {
  width: 100%;
  border: 1px solid grey;
  padding: 0px 0px 0px 20px;
  border-bottom: none;
  border-top: none;
  border-right: none;
  margin-top: 20px;
}

.genaeral_details {
  color: #707070;
  font-weight: 400;
  font-size: 14px;
}

.general_head {
  color: black;
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
}

.generalduties_details_container {
  display: flex;
  align-items: center;
  font-family: "Roboto";
  width: 100%;
}

.time-inhours {
  border: 1px solid var(--app-theme);
  border-radius: 20px;
  /* width: 30%; */
  height: 40px;
  /* font-size: 15px; */

  font-size: 14px;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: var(--app-theme);
}

.road_dot {
  margin-left: 3px;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.common_nurse_view_name {
  color: black;
  font-size: 18px;
  font-family: "Roboto";
  text-align: center;
  text-transform: capitalize;
}

.common_nurse_view_age {
  font-size: 14px !important;
  color: grey;
  text-align: center;
  /* text-transform: capitalize; */
}

.Thingsof {
  font-size: 14px !important;
  color: grey;
  text-align: center;
  text-transform: capitalize;
  margin-left: -4px;
}

.common_nurse_view_label {
  font-size: 16px !important;
  color: black !important;
  font-weight: 400 !important;
}


.date_align {
  padding-left: 20px;
}

.Total_content_align {
  position: relative;
  left: 40px;
  top: 2px;
}
.nurse_postion{
  position: relative !important;
  left: 25px !important;
}

.common_nurse_view_div {
  font-size: 16px !important;
  color: #707070 !important;
  font-weight: 400 !important;
  position: relative !important;
  left: 25px !important;
}

.instruction-view-more {
  cursor: pointer;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 0px;
}


.booking_head_div {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: black !important;
  text-align: center;
}

.nurse_divider_container {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-right: 20px;
}

.nurse_divider_1px {
  height: 1px;
  border-radius: 20%;
  width: 100%;
  background-color: #808080;
}

.nurse_view_name_wrap {
  /* color: rgb(180, 180, 180) !important; */
  color: #707070;
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto" !important;
}

.nurse_view_subheader {
  width: 100%;
  margin-top: 10px;
  font-size: 1.25rem;
}

.nurse_view_qualitiy {
  width: 30%;
  /* color: rgb(180, 180, 180) !important; */
  /* color: #666666; */
  color: black;
  font-size: 16px;
  font-weight: 400;
  font-family: "Roboto" !important;
}

.nurse_view_colon {
  margin-right: 15px;
}

.nurse_view_values {
  color: #707070;
  font-family: Roboto !important;
  font-weight: 400;
  font-size: 14px;
}

.address_edit {
  font-size: 13px;
}

.nurse_view_cancel {
  margin-right: 20px !important;
  margin-bottom: 20px !important;
}

.nurse_modelcancel {
  font-family: "Roboto" !important;
  font-size: 1.5rem !important;
  background-color: rgb(254, 236, 236) !important;
  border: 1px solid rgb(249, 168, 168) !important;
  color: rgb(247, 89, 89) !important;
}

.nurse_view_cancel:focus {
  outline: none !important;
}

.nurse_view_image_grid {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.close_icon {
  display: flex;
  justify-content: flex-end;
  ;
  cursor: pointer;
  color: #ff0068 !important;

}

/*@media only screen and (max-width: 1000px){
    .nurse_view_cancel{
        width: 80px !important;

    }
    .nurse_view_cancel{

        margin-right: 10px !important;
        margin-bottom:10px !important;
        }
        .nurse_view_name_wrap{
            margin-top: 3px;
            position: relative;
        }
        .nurse_view_qualitiy{
            width: 30%;
        }
       
        .nurse_view_grid_container{
            min-height: 400px !important;
        }
        .nurse_view_subheader{
            width: 100%;
            margin-top: 5px;
        
        }
       
}*/
@media only screen and (max-width: 768px) {
  .nurse_view_image_grid {
    justify-content: center;
  }

  .generalduties_details {
    width: 100%;
    border: 1px solid grey;
    border-bottom: none;
    padding: 0px;
    border-left: none;
    border-right: none;
    margin-top: 20px;
  }

  .nurse_divider_container {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 959px) {
  .nurse_view_image_grid {
    justify-content: center;
  }

  .generalduties_details {
    width: 100%;
    border: 1px solid grey;
    border-bottom: none;
    border-left: none;
    padding: 0px;
    border-right: none;
    margin-top: 20px;
  }

  .nurse_divider_container {
    padding-right: 0px;
  }
}

.instu_open_bg_blur .instruction-view-more {
  z-index: -2;
}

._zIndex{
  z-index: -2 !important;
}

.text_ellipsis_div {
  max-width: 43px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  ;
}

.remain-instruction-popover .MuiPopover-paper {
  padding: 5px 0px 20px 0px;
  border-radius: 20px;
  max-width: 12% !important;
  min-width: 15% !important;
}

.remain-instruction-div .header-div {
  display: flex;
  justify-content: space-between;
  background-color: #510f30;
  position: relative;
  top: -5px;
  align-items: center;
}

remain-instruction-div .header-divsss {
  display: flex;
  justify-content: space-between;
  background-color: #510f30;
}

.remain-instruction-div .title {
  color: white;
  font-size: 16px;
  margin-left: 11px;
  margin-top: 8px
}

p {
  margin-top: 0;
  margin-bottom: 0px !important;
}

.remain-instruction-div .body-div {
  margin: 10px;
}

.remain-instruction-div .items {
  margin: -5px;
  padding: 5px;
  font-size: 14px;
  /* background-color: var(--app-theme); */
  color: black;
  border-radius: 10px;
  font-weight: 500;
}

.remain-instruction-popover {
  z-index: 99999 !important;
}

.MuiPopover-root .remain-instruction-popover div:first-child {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.view_more_closeicon_title {
  color: #ff0068 !important;
  height: 20px !important;
  width: 25px !important;
  cursor: pointer;
  margin-right: 5px;
}

.view_backdrop {
  z-index: 99999;
}