@font-face {
  font-family: "Roboto";
  src: url("../src/Font/Roboto-Regular.ttf") format("truetype");

  font-family: 'Titillium Web';
  src:url('../src/Font/Titillium_Web/TitilliumWeb-Regular.ttf')format('truetype');
}
body {
  font-family: "Roboto";
}

:root {
  --app-theme: #510F30;
  --app-plus-blue: #00E8D2;
  --pink-color:#FF0068;
  /* Define a root variable for primary color */
  /* #83af40 Old color code */
}

.ant-select-selection:active {
  -webkit-box-shadow: none !important;
  box-shadow: 0px !important;
}
.ant-select-open .ant-select-selection {
  -webkit-box-shadow: none !important;
}
.ant-btn:hover {
  border-color: none !important;
}
.ant-btn:hover .ant-btn:focus {
  color: none !important;
  border-color: none !important;
}
.ant-btn:active {
  border-color: none !important;
  color: none !important;
}
.anticon-download{
  display: none;
}

/*message*/
.ant-message-notice {
  margin-top: 65px !important;
}

.ant-message .anticon {
  top: -2px !important;
}

/* *{
  font-family: "Roboto" !important;
} */
.MuiDialog-paper{
  border-radius: 30px !important;
}
._1ceqH ._1Yplu ._1Pz2d{
  /* margin-left: -30px !important; */
  display: flex !important;
}
._1ceqH ._1Yplu{
  display: flex !important;
  margin-left: -28px !important;
}
._1ceqH ._1YOWG ._eV_dK{
  text-align: center !important;
}
.MuiDialog-paperFullWidth {
  width: 400px;
}
.content_body{
  font-size: 14px !important;
}
.content_head{
  font-size: 16px !important;
}
.notification_container{
  display : flex;
  width: 500px !important;
  }
  
  .notification_img_container{
  margin: 3px;
  width : 20%;
  }
  
  .notification_img{
  display : flex;
  margin: 3px;
  width: 100%;
  height: 100%;
  }
  
  .notification_imgs{
    display: flex;
    margin: 3px;
    width: 107%;
    height: 70%;
    margin-top: 10px;
  }

  .notification_text_container{
  margin: 3px;
  width : 80%;
  }
  
  .notification_text_head{
  margin : 5px 10px;
  font-size: 16px !important;
  }
  .notification_text_body{
  font-size: 14px !important;
  margin : 5px 10px;
  }
  .go2072408551{
    max-width: fit-content !important;
  }
  .MuiDialog-paperFullWidth {
    overflow-y: auto !important;
  }
  .add_nurse_model .MuiDialog-paperFullWidth{
    overflow-y: auto !important;
  }

  .MuiTablePagination-select {
    text-align: none !important; 
    text-align-last: none !important; 
  }
  .MuiSelect-select:not([multiple]) option, .MuiSelect-select:not([multiple]) optgroup {
    text-align: center !important;
  }