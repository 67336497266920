.MuiAppBar-colorPrimary {
  background-color: white !important;
}

/* .MuiSvgIcon-root {
    fill: grey !important;
    font-size: 1.75rem !important;
} */
.MiniDrawer-menuButton-4 {
  margin-left: 100px !important;

  margin-right: 0px !important;
}

.drawer-logo-container {
  position: fixed;
  /* top: 0; */
  left: 5px;
  width: 70px;
  /* height: auto; */
}

.logo {
  width: 110px;
  height: 50px;
}

.icon-container {
  width: 100%;
  height: auto;
}

.icon {
  width: 33px;
  height: auto;
  font-size: 2rem !important;
  fill: #333 !important;
}

.notification-icon {
  width: 35px;
  height: auto;
}

.notification {
  padding-top: 12px;
  width: 70%;
  height: auto;
}

.MuiBadge-colorSecondary {
  background-color: var(--app-theme) !important;
}

.MuiBadge-anchorOriginTopRightRectangle {
  top: 6px !important;
  right: 20px !important;
}

.MuiBadge-dot {
  height: 8px !important;
  padding: 0;
  min-width: 8px !important;
}

.list-icons .MuiTypography-body1 {
  font-family: "Roboto" !important;
  font-size: 1.5rem !important;
}

.btn-my_style:focus {
  box-shadow: none !important;
}

.MuiTablePagination-input {
  font-size: 1.5rem !important;
  font-family: "Roboto" !important;
  margin-top: 10px !important;
}

.MuiTablePagination-selectIcon {
  top: 1px;
  font-size: 2.5rem !important;
}

.MuiList-padding {
  padding-bottom: 2px !important;
}

.MuiListItem-gutters {
  font-size: 1.25rem !important;
  font-family: "Roboto" !important;
}

.btn-my_style:active {
  outline: none !important;
}

.MuiTypography-body1 {
  font-size: 16px !important;
  font-family: "Roboto" !important;
}

.dropdown-container {
  width: 100%;
  height: auto;
}

.dropdown-container .dropdown {
  width: 97%;
  display: flex !important;
  justify-content: flex-end;
}

#dropdown-basic {
  font-size: 1.75rem;
  font-family: "Roboto";
  box-sizing: border-box;
  /* border-left: 1.5px solid #b1b1b1; */
  padding-left: 10px;
}
.arabic_cls #dropdown-basic {
  font-size: 1.75rem;
  font-family: "Roboto";
  box-sizing: border-box;
  /* border-left: 1.5px solid #b1b1b1; */
  width: 100%;
  padding-left: 10px;
  text-align: right;
}

#dropdown-basic:hover {
  background-color: #fafafa;
}

.dropdown-menu {
  font-size: 1.75rem;
}

.MuiStepper-alternativeLabel.MuiPaper-root {
  background-color: #fafafa !important;
}

.MuiPaper-root {
  color: #707070 !important;
  font-size: 14px;
  font-weight: 400;
}

.date-wrapper1 {
  width: 100%;
  height: auto;
}

.date-wrapper2 {
  padding-left: 12px;
  width: 100%;
  display: flex;
  justify-content: left;
  font-family: "Roboto";
}

.date {
  font-size: 10px;
}

.dropdown-container_close {
  width: 100%;
  height: auto;
}

.dropdown-container_close .dropdown {
  width: 96%;
  display: flex !important;
  justify-content: flex-end;
}

.dropdown-container_close #dropdown-basic {
  font-size: 1.75rem;
  font-family: "Roboto";
  box-sizing: border-box;
  border-left: 1.5px solid #b1b1b1;
  padding-left: 10px;
}

.dropdown-container_close .date-wrapper1 {
  width: 100%;
  height: auto;
}

.dropdown-container_close .date-wrapper2 {
  width: 95%;
  display: flex;
  justify-content: flex-end;
  font-family: "Roboto";
}

.drawerpage_container .MuiAvatar-root {
  position: absolute !important;
  right: 10px;
  top: 15px;
}

.MuiListItem-gutters {
  padding-bottom: 16px !important;
}

.dropdown-menu {
  font-family: "Roboto";
  font-size: 1.5rem;
}

.drawerpage_container button:focus {
  outline: 0px !important;
}

.appbar_sideicons .MuiListItem-button:hover {
  color: var(--app-theme);
}

.appbar_sideicons .MuiListItem-button:focus {
  text-decoration: none;
  color: var(--app-theme);
  outline: none;
}

.appbar_sideicons .MuiMenuItem-root:hover path {
  fill: var(--app-theme);
}

.appbar_sideicons .MuiMenuItem-root:focus path {
  fill: var(--app-theme);
}

.appbar_sideicons .MuiMenuItem-root:hover rect {
  fill: var(--app-theme);
}

.appbar_sideicons .MuiMenuItem-root:focus rect {
  fill: var(--app-theme);
}

.drawerpage_container .MuiDrawer-paper {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}

@media only screen and (max-width: 1165px) {
  .img {
    width: 85%;
  }

  .dropdown-container_close .dropdown {
    width: 94%;
    display: flex !important;
    justify-content: flex-end;
  }

  .dropdown-container_close .date-wrapper2 {
    width: 93%;
    display: flex;
    justify-content: flex-end;
    font-family: "Roboto";
  }
}

@media only screen and (max-width: 959px) {
  .img {
    width: 85%;
  }

  .dropdown-container_close .dropdown {
    width: 93%;
    display: flex !important;
    justify-content: flex-end;
  }

  .dropdown-container_close .date-wrapper2 {
    width: 92%;
    display: flex;
    justify-content: flex-end;
    font-family: "Roboto";
  }
}

@media only screen and (max-width: 875px) {
  .dropdown-container .dropdown {
    width: 95%;
  }

  .date-wrapper2 {
    width: 94%;
  }

  .dropdown-container_close .dropdown {
    width: 91%;
    display: flex !important;
    justify-content: flex-end;
  }

  .dropdown-container_close .date-wrapper2 {
    width: 90%;
    display: flex;
    justify-content: flex-end;
    font-family: "Roboto";
  }
}

@media only screen and (max-width: 720px) {
  .dropdown-container .dropdown {
    width: 94%;
  }

  .date-wrapper2 {
    width: 92%;
  }
}

@media only screen and (max-width: 632px) {
  .dropdown-container .dropdown {
    width: 93%;
  }

  .date-wrapper2 {
    width: 91%;
  }

  .dropdown-container_close .dropdown {
    width: 90%;
    display: flex !important;
    justify-content: flex-end;
  }

  .dropdown-container_close .date-wrapper2 {
    width: 89%;
    display: flex;
    justify-content: flex-end;
    font-family: "Roboto";
  }
}

@media only screen and (max-width: 608px) {
  .dropdown-container .dropdown {
    width: 91%;
  }

  .date-wrapper2 {
    width: 89%;
  }

  .dropdown-container_close .dropdown {
    width: 88%;
    display: flex !important;
    justify-content: flex-end;
  }

  .dropdown-container_close .date-wrapper2 {
    width: 86%;
    display: flex;
    justify-content: flex-end;
    font-family: "Roboto";
  }
}

@media only screen and (max-width: 550px) {
  .dropdown-container .dropdown {
    width: 89%;
  }

  .date-wrapper2 {
    width: 86%;
  }
}

@media only screen and (max-width: 518px) {
  .dropdown-container .dropdown {
    width: 88%;
  }

  .date-wrapper2 {
    width: 85%;
  }

  .dropdown-container_close .dropdown {
    width: 86%;
    display: flex !important;
    justify-content: flex-end;
  }

  .dropdown-container_close .date-wrapper2 {
    width: 84%;
    display: flex;
    justify-content: flex-end;
    font-family: "Roboto";
  }
}

@media only screen and (max-width: 455px) {
  .makeStyles-menuButton-4 {
    margin-left: 50px !important;
    margin-right: 0px !important;
  }

  .dropdown-container .dropdown {
    width: 90%;
  }

  .date-wrapper2 {
    width: 88%;
  }

  .dropdown-container_close .dropdown {
    width: 84%;
    display: flex !important;
    justify-content: flex-end;
  }

  .dropdown-container_close .date-wrapper2 {
    width: 82%;
    display: flex;
    justify-content: flex-end;
    font-family: "Roboto";
  }
}

@media only screen and (max-width: 372px) {
  .dropdown-container .dropdown {
    width: 86%;
  }

  .date-wrapper2 {
    width: 85%;
  }
}

@media only screen and (max-width: 315px) {
  .makeStyles-menuButton-4 {
    margin-left: 40px !important;
    margin-right: 0px !important;
  }
}

.dropdown-menu {
  position: absolute;
  will-change: transform;
  left: 0px;
  transform: translate3d(915px, 36px, 0px);
  margin-top: 25px;
}

.show .dropdown-menu.show {
  top: 0px !important;
  min-width: 30rem !important;
}

.dropdown-menu.show .MuiDivider-root {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.Avatar {
  margin-top: 10%;
  width: 100px !important;
  height: 100px !important;
  border-radius: 50%;
  object-fit: cover;
}

.name_email {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;

}

.dropdown-img {
  display: flex;
  justify-content: center;
}

.hamburger_icon {
  margin-left: 100px;
}

.profile_logout_privacy {
  display: flex;
  align-items: center;
  font-family: "Roboto" !important;
  justify-content: center;
  font-size: 1.5rem;
  margin-top: 10px;
  color: #c6c4c4;
  padding: 10px;
}

.profile_logout_butt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
  padding: 10px;
  font-family: "Roboto" !important;
  color: #3c3939;
}

.profile_logout_butt p {
  color: #3c3939 !important;
  text-decoration: none;
}


.logout_butt {
  box-shadow: 0 0 2px 0px #ccc;
  font-family: "Roboto" !important;
  font-size: 1.25rem !important;
  color: #3c3939 !important;
  padding: 8px 12px !important;
  box-shadow: 0 0 2px;
}

.profile_logout_butt p {
  margin-bottom: 0px !important;
}

.appbar_sideicons .MuiMenuItem-root {
  padding-top: 4px !important;
}

.appbar_sideicons .MuiListItem-gutters {
  padding-bottom: 15px !important;
  padding-top: 15px !important;
}

.appbar_sideicons {
  overflow-y: scroll;
  border-top: 1px solid #e0e0e0;
}

.active_text_heading,
.active_text_heading.MuiMenuItem-root path,
.active_text_heading.MuiMenuItem-root rect {
  color: var(--app-theme) !important;
  text-decoration: none !important;
  background-color: rgba(0, 0, 0, 0.04) !important;
  fill: var(--app-theme);
}

.appbar_sideicons a div,
.appbar_sideicons a div span {
  font-size: 16px !important;
}

.appbar_sideicons a .MuiTypography-body1 {
  font-weight: 400 !important;
}

.drawerpage_container .MuiDrawer-paper {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}

.MuiPaper-elevation4 {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px !important;
}

.MuiDrawer-paperAnchorDockedLeft {
  border-right: unset !important;
}

/* Arabic Language css*/

.language_icon {
  width: 30px !important;
  height: auto !important;
  cursor: pointer;
  margin-right: 5px;
  color: #510F30;
}


.arabic_cls .language_icon {
  margin-right: 0px !important;
  margin-left: 5px;
}

.arabic_cls header {
  left: 0;
  margin-left: 0px !important;
}

.arabic_cls.drawerpage_container .MuiDrawer-root {
  order: 1;
}

.arabic_cls.drawerpage_container main {
  order: 0;
}

.arabic_cls.drawerpage_container .drawer-logo-container {
  position: absolute;
  left: calc(100% + 150px);
}

.arabic_cls .dropdown-container .dropdown {
  justify-content: start !important;
  margin-left: 40px !important;
}

.arabic_cls .Avatar-image {
  position: absolute !important;
  left: 10px;
}

.arabic_cls .date-wrapper2 {
  width: 100%;
  justify-content: right !important;
  padding-left: 65px;
  /* justify-content: center !important; */
  padding: 0 10px 0 0px !important;
}

.arabic_cls .MuiBadge-root {
  order: 1;
}

.uesrname-border{
  border-left: 1.5px solid #b1b1b1 !important;
  padding: 0 0 0 10px !important;
}

.arabic_cls .uesrname-border{
  border-right: 1.5px solid #b1b1b1 !important;
  border-left: 0 !important;
  padding: 0 10px 0 0px !important;
}
/* 
.arabic_cls #dropdown-basic {
  border-left: 0px !important;
  border-right: 1.5px solid #b1b1b1;
} */

.arabic_cls .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.arabic_cls .dropdown-toggle::after {
  display: none !important;
}

.arabic_cls .appbar_sideicons .MuiListItemIcon-root {
  order: 1;
  text-align: right;
}

.arabic_cls .appbar_sideicons .MuiListItemText-root {
  text-align: right;
}

/* .supportIcon{
  width:30px;
  height:30px;
 } */
#supportIcon {
  width: 30px;
  height: 30px;
}

#supportIcon:hover {
  width: 30px;
  height: 30px;
  content: url("../../Images/support_image_green.png");
  color: var(--app-theme);

}


.step_head_img {
  width: 100%;
  /* height: 100%; */
  cursor: pointer;
  border-radius: 10px;
  /* position: relative;
  margin: 0 auto; */
}

.stepper_line_show .MuiStepIcon-completed {
  fill: var(--app-theme) !important;
  height: 40px !important;
  width: 40px !important;
  /* margin-top: -5px; */
}
.declined_re{
  color: #ff0068 !important;
}

/* =================== */
.MuiStepIcon-root.MuiStepIcon-active {
  color: white !important;
}


.Mui-active svg,
.stepper_line_show .MuiStepIcon-active {
  border: 1px solid grey !important;
  border-radius: 100%;
  height: 40px !important;
  width: 40px !important;
  margin-top: 0px;
}

.Mui-disabled svg {
  /* height: 22px !important;
  width: 21px !important;
  margin-top: 0px; */
  border: 1px solid grey !important;
  border-radius: 100%;
  height: 40px !important;
  width: 40px !important;
  margin-top: -2px;
}

.Mui-disabled circle,
.Mui-active circle {
  fill: white;
}

.play_img {
  /* margin-top: -3rem;
    margin-right: 0.9rem;
    position: relative;*/
  cursor: pointer;
  margin-top: -40px;
  position: relative;
  /* height: 28px; */
  right: -258px;
}

.disable_card {
  opacity: 0.5;
}

.verification_img_div {
  width: 71%;
  height: 170px;
  /* box-shadow: 0px 0px 5px 1px; */
  border-radius: 10px;
  /* background: white; */
  float: left;
  position: relative;
  z-index: 9;
  left: 30px;
  display: flex;
  flex-direction: column;

}

.verification_head {
  /*background-color: lightgrey;*/
  width: 95%;
  margin: auto;
  position: relative
}

/* .verification_head h1 {
  padding: 10px 0px;
} */



.verification_head h1 {
  padding: 10px 0px;
  padding: 10px 0px;
  box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, .14), 0 0px 7px 0 rgba(0, 0, 0, .12) !important;
  color: #510F30;
  font-weight: 400 !important;
}


.left_arrow_dashboard {
  cursor: pointer;
  font-size: 4rem !important;
  fill: black !important;
  margin-top: -3px;
}

.right_arrow_dashboard {
  margin-right: 10px;
  cursor: pointer;
}


.collaterals_video_ul {
  padding: 0px;
}

.collaterals_video_ul li {
  display: inline;
  text-decoration: none;
  padding: 8px;
  cursor: pointer;
  font-size: 15px;
}

.collaterals_video_ul li.active {
  color: var(--app-theme);
  border-bottom: 5px solid var(--app-theme);
}

.menu_play_img {
  cursor: pointer;
  position: absolute;
  top: 40%;
  right: 73%;
}
.arabic_cls .menu_play_img {
  cursor: pointer;
  position: absolute;
  top: 46%;
  left: 73%;
  width: 28px;
}

.review_approved {
  color: #510F30;
}

.clickstart {
  font-family: 'Titillium Web !important';
  /* font-family: "Roboto" !important; */
  color: rgb(81, 15, 48);
  /* font-weight: 400; */
  /* font-size: '16px';
     color: '#510F30';
     margin-left:"358px";
     text-align:'center';
     margin-top:'11px';
     font-weight: '800px' */


}

.click_underline {
  margin-top: 3px;
  text-align: center;
}

.click_underline:active {
  text-decoration: underline;
}

/* @media only screen and (max-width: 600px) {
  .play_img {
    right: -225px;
    margin-top: -65px;
  }
} */

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {
  .play_img {
    right: -85px;
    margin-top: -120px;
  }
} */

/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 768px) {
  .play_img {
    right: -120px;
    margin-top: -100px;
  }
} */

/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {
  .play_img {
    margin-top: -80px; 
    right: -170px;
  }
} */

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {
  .play_img {
  margin-top: -40px;
  right: -258px;
}

} */
.verification_img_div1 {
  position: relative;
  display: flex;
  flex-direction: row;

}

.ofing {
  /* position: absolute;
  bottom: 0px;
  right: -4px; */
  /* position: fixed;
  right: 55%;
  top: 48%; */
  /* margin: 0; */
  /* display: flex;
align-items: end;
justify-content: end; */
  /* align-items: flex-end */
  /* bottom: 0%;
right: -8%; */
  /* bottom: 0%; */
  /* right: -30%; */
  /* display: flex; */
  /* align-items: flex-end ; */
  /* justify-content: start; */
  margin-top: auto;
  /* margin-right: auto; */
  /* position: absolute; */
  /* float: right; */
  /* margin-left: '-15px'; */
}

.ofing1 {
  /* float:left; */
  margin-left: '-15px';
  /* position: fi; */
}

.wowww {
  width: 60px;
  height: 70px;
}

.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
  padding-right: 0;
  margin-top: -1px;

}

.start_button{
  padding: 3px 19px 3px 19px;
  border-radius: 20px;
  font-size: 16px;
  color: white;
  background-color: #510f30;
  border-color: black;
  font-family: titillium web !important;
  font-weight: 500 !important;
  margin-top: 5px;
  margin-left: 78px;
}
.MuiTablePagination-selectRoot{
  margin-left: 8px !important;
  margin-right: 32px !important;
  margin-top: 1px !important;
}

.arabic_cls .MuiStepConnector-alternativeLabel {
  top: 12px;
  left: calc(-48% + 20px);
  right: calc(47% + 20px);
  position: absolute;
}
.withI18nextTranslation\(MiniDrawer\)-appBarShift-3 {
  width: calc(100%) !important;
}
.withI18nextTranslation{
  width: none !important;
}
.withI18nextTranslation\(MiniDrawer\)-verificationdrawer-11 {
  height: 58px !important;
}