@font-face {
  font-family: "Roboto";
  src: url("../src/Font/Roboto-Regular.ttf") format("truetype");
  font-family: 'Titillium Web';
  src: url('../src/Font/Titillium_Web/TitilliumWeb-Regular.ttf')format('truetype');
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto" !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* html {
  font-family: "Roboto" !important;
}
code {
  font-family: "Roboto" !important;
}
#root {
  font-family: "Roboto" !important;
}
p {
  margin-bottom: 2px;
} */
.Dialogmodal .MuiDialogTitle-root {
  box-shadow: none;
  background-color: var(--app-theme);
}

body *::-webkit-scrollbar {
  width: 6px;
  height: 4px;
  transition: 0.3s;
}

body *::-webkit-scrollbar-thumb {
  background: #ADAAA8;
  border-radius: 10px;
}

.dashboard_header {
  padding: 5px 15px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px 5px 0px 0px;
  opacity: 1;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.dashboard_title {
  font-size: 22px;
  color: var(--app-theme);
  /*  margin-top: 10px;
  margin-left: 2%;*/
  font-family: titillium web !important;
  font-weight: 100;
}



.nurse_view_common_popup .MuiDialog-paperWidthMd {
  max-width: 700px !important;
}


.offlinemsg_body{
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  z-index: 9999999999;
}
.offlinemsg_body_img{
  width: 300px;
  margin: auto;
}
.offlinemsg_body_img img{
  width: 100%;
}
.offlinemsg_content{
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-weight: 500;
  color: #510f30;
  font-style: normal;
}