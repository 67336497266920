.doctor_dashboard_view{
 width:100%;
 height:100%;
 display: flex;
 align-items: flex-end;
 justify-content: center;
  margin: 120px auto 30px;
  background-color: #fff;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  text-align: center;
}
.doctor_popup_details .MuiDialog-paperWidthSm {
  width:400px !important;
}
.MuiDialog-paper{
  overflow-y: initial !important;
}
.patient_name{
  font-size: 18px !important;
  display: flex;
  align-items: center;
  justify-content: center;
   font-weight: 400;
}
.patient_age{
  display:flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color:grey;
}
.profile_patientappointment_details{
  color: black;
  font-size: 17px;
  display: flex;
  align-items: center;
  font-weight: bold;
}
.patient_date{
  color:grey;
  font-size: 1.5rem;
  margin-left: 20px;
}
.profile_patientappointment_details-div{
  display:flex;
  flex-direction: column;
  margin-right: 16px;
 
}
.view_detailsbutton{
  color:#fff !important;
  font-size: 1.25rem !important;
  font-weight: bold !important;
  width: 134px !important;
    font-size: 18px !important;
    height:38px !important;
    border-radius: 2px !important;
  background-color: rgb(71, 196, 88) !important;
}
.right_arrowview{
  font-size: 3rem !important;
}
.doctor_details_container{
  width:100%;
  padding:5px 20px 20px 20px;
  
}
.doctor_detailsdiv{
  width:100%;
  height:100%;
}
.patient{
  background-color: white;
  border-radius: 50%;
  height: 150px;
  width:150px;
  position: absolute;
  top: -70px;
  z-index: 10000;
  left: calc(50% - 75px);
  padding:5px;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12) !important;
}
.divider_root{
  /* margin-top: 20px !important;
  height:2px !important; */
  border-top: dotted;
  opacity: 0.3;
  margin-left: 16px;
  margin-right: 22px;
}
.patient:hover{
transition: all 0.3s ease-in-out !important;
  -webkit-transition: all 0.3s ease-in-out !important;
  -moz-transition: all 0.3s ease-in-out !important;
}
.nurse_profile_modal .MuiDialog-paperWidthSm
{
  width:60% !important;
}
@media only screen and (min-width: 768px){
  .nurse_profile_modal .MuiDialog-paperWidthSm
{
  width:50% !important;
  /* margin-left: -5%;
    margin-top: -16%; */
}
}




/* saran */

.nurse_profile_modal{
  z-index: 5000 !important;
}





.booked_details_font{
  font-size: 16px !important;
  /* font-weight: 600 !important; */
  color: black;
}
.fromdate_adjust{
  color: #5a5757;
  font-size: 17px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* font-weight: 600; */
}
.fromdate_adjust_div{
  display:flex;
  /* align-items: center; */
  /* justify-content: center; */
  flex-direction: column;
  margin-right: 16px;
}
.book_date_adjust{
  margin-left: 20% !important;
}
.to_date{
  color:grey;
  font-size: 1.5rem;
  margin-left: 40px;
}
.vertical_line{
  border-right: dotted;
  opacity: 0.3;
  margin-top: 2%;
}
.billed_adjust{
  margin-left: 12% !important;
  margin-right: -16% !important; 
}
.close_ico{
  position: absolute !important;
  height: 25px !important;
  right: 15px;
  top: 15px;
  cursor: pointer;
  color:#ff0068 !important;
}
.date_align{
  padding-left: 20px;
}
.Room_align{
  padding-left: 20px;
  padding-top: 2%;
}

.profile_detail_main_div{
  display: flex;
  padding-bottom: 1rem;
}

.profile_detail_lable_div
{
  width: 45%;
  font-weight: bold;
  color:black;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
}

.profile_detail_colon_div
{
  width: 10%;
  text-align: center;
  font-size: 17px;
}
.profile_detail_div{
  width: 45%;
  text-align: left;
  font-size: 14px;
  color: #707070;
  font-weight: 400;
}

.date_div{
  margin-left: 15% !important;
}

.duties_main_div{
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
}

.duties_body_div{
  padding: 1rem;
  border: 1px solid;
  border-radius: 10px;
  margin: 5px;
  font-size: 14px;
  color: #707070;
}