.cell_eye {
  padding-left: 0px !important;
}

.booking_yet_to_start_table_div table thead tr th:nth-child(1),
.booking_yet_to_start_table_div table tbody tr th:nth-child(1),
.booking_yet_to_start_table_div table thead tr th:nth-child(3),
.booking_yet_to_start_table_div table tbody tr td:nth-child(3),
.booking_yet_to_start_table_div table thead tr th:nth-child(4),
.booking_yet_to_start_table_div table tbody tr td:nth-child(4),
.booking_yet_to_start_table_div table thead tr th:nth-child(6),
.booking_yet_to_start_table_div table tbody tr td:nth-child(6),
.booking_yet_to_start_table_div table thead tr th:nth-child(7),
.booking_yet_to_start_table_div table tbody tr td:nth-child(7),
.booking_yet_to_start_table_div table thead tr th:nth-child(8),
.booking_yet_to_start_table_div table tbody tr td:nth-child(8),
.booking_yet_to_start_table_div table thead tr th:nth-child(9),
.booking_yet_to_start_table_div table tbody tr td:nth-child(9),
.booking_yet_to_start_table_div table thead tr th:nth-child(10),
.booking_yet_to_start_table_div table tbody tr td:nth-child(10)

{
  text-align: center;
  justify-content: center !important;
}


.arabic_cls .booking_yet_to_start_table_div table thead tr th:nth-child(10),
.arabic_cls .booking_yet_to_start_table_div table tbody tr th:nth-child(10),

.arabic_cls .booking_yet_to_start_table_div table thead tr th:nth-child(7),
.arabic_cls .booking_yet_to_start_table_div table tbody tr td:nth-child(7),

.arabic_cls .booking_yet_to_start_table_div table thead tr th:nth-child(5),
.arabic_cls .booking_yet_to_start_table_div table tbody tr td:nth-child(5),
.arabic_cls .booking_yet_to_start_table_div table thead tr th:nth-child(4),
.arabic_cls .booking_yet_to_start_table_div table tbody tr td:nth-child(4),
.arabic_cls .booking_yet_to_start_table_div table thead tr th:nth-child(3),
.arabic_cls .booking_yet_to_start_table_div table tbody tr td:nth-child(3),
.arabic_cls .booking_yet_to_start_table_div table thead tr th:nth-child(2),
.arabic_cls .booking_yet_to_start_table_div table tbody tr td:nth-child(2),
.arabic_cls .booking_yet_to_start_table_div table thead tr th:nth-child(1),
.arabic_cls .booking_yet_to_start_table_div table tbody tr td:nth-child(1)
{
  text-align: center !important;
  justify-content: center !important;
}
.arabic_cls .booking_yet_to_start_table_div table thead tr th:nth-child(6),
.arabic_cls .booking_yet_to_start_table_div table tbody tr td:nth-child(6),
.arabic_cls .booking_yet_to_start_table_div table thead tr th:nth-child(9),
.arabic_cls .booking_yet_to_start_table_div table tbody tr td:nth-child(9)
{
  text-align: right !important;
  justify-content: right !important;
}
.MuiTableCell-paddingNone:last-child{
  text-align: center;

}