.imgContainer {
    display: flex;
    justify-content: space-between;
}

.arCommissionManageDiv {
    direction: rtl;
    text-align: right;
}

.commissionContainer {
    display: flex;
    justify-content: space-between;
    padding: 5px 0px;
}

.imgChildContainer {
    display: flex;
    place-items: center;
}

.color {
    color: #510F30;
}

.paymentMethodContainer {
    border: 1px solid #EFEFEF;
    border-radius: 12px;
    box-shadow: 0px 0px 5px 0px;
    padding: 10px;
    margin: 15px;
}

.paymentMethod {
    padding-left: 5px;
    color: #510F30
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 3px solid rgba(0, 0, 0, .1);
}

.commisionDetails{
    width: 50%;
    padding-left: 8px;
}

.knetCommisionDetails{
    width: 50%;
    padding-left: 25px;
}
.cardtype{
    margin: 0px !important;
}