.table {
    font-family: "Roboto" !important;
}

.print_header {
    font-size: 12px !important;
    color: #fff !important;
    background-color: #510f30 !important;
}



.pdfDataTitle {
    font-weight: 500 !important;
    font-size: 17px !important;
    margin: 10px 0;
    color: #510f30 !important;
    display: flex !important;
    justify-content: center !important;
    /* text-transform: uppercase !important; */
}

.print_head_Text_color {
    font-size: 10px !important;
    columns: #510f30 !important;
    background-color: #f8e0d8 !important;
    text-align: end !important;
}

.print_head_Text_color_Total {
    font-size: 10px !important;
    columns: #510f30 !important;
    background-color: #f8e0d8 !important;
    text-align: center !important;
}

.print_header_textss1 {
    font-size: 10px !important;
    background-color: #fff !important;

}

.print_header_total {
    background-color: #fff !important;
}

.pdfttabledata {
    margin: 100px 80px 80px 80px !important;
}

.pdfttabledata td,
.pdfttabledata th {
    margin: 100px 80px 80px 80px !important;
    /* border: 1px solid #4a4949; */
    text-align: left;
    padding: 10px 5px;
    width: 300px !important;
    letter-spacing: normal !important;
}

.kwd_vlaues_font {
    display: flex !;
    text-align: end !important;
    justify-content: end !important;
    font-size: 12px !important;
    color: #510f30 !important;
}

.pdfttabledata table {
    width: 100%;
    border-collapse: collapse;
}

.pdfttabledata th,
.pdfttabledata td {
    border: 0.5px solid #000;
    /* Add border for better visualization */
    padding: 8px;
    text-align: left;
    white-space: nowrap;
    /* Prevents text from wrapping */
}

.pdfttabledata th {
    background-color: #510f30;
}

.print_header_text1 {
    text-align: center !important;
}

.header_tom {
    height: 8px !important;
}

/* Ensure the logo and header are visible and repeated */
@media print {

    html,
    body {
        height: auto;
    }

    .pdfttabledata {
        display: block !important;
    }

    thead {
        display: table-header-group !important;
    }

    .print_header,
    .print_header_total {
        break-inside: avoid !important;
    }

    .print_header_text1,
    .print_header_textss1 {
        break-inside: avoid !important;
    }

    .pdfPage {
        page-break-after: always !important;
    }
}

.header_footer {
    padding-top: 50% !important;
}

.page-count {
    font-size: 12px;
    display: flex;
    text-align: right;
    justify-content: end;
    margin-top: 100px;
}

.tom_logo_print {
    height: 50px !important;
    width: 100px !important;
}
