.common_success_tick_mark{
   
  height: 80px;
  width: 80px;
    background-repeat: no-repeat;
  }

.FilterSubmit {
    font-size: 16px !important;
    background-color: var(--app-theme) !important;
    color: #fff !important;
    text-transform: none !important;
    border-radius: 50px !important;
    padding: 5px 20px 5px 20px;
    border-color: var(--app-theme);
    padding: 5px 30px !important;
  }

.warning_icon
{
    fill:#f45959 !important;
    height: 50px !important;
    width: 50px !important;
  }

.success_label{
  margin-top:2rem;
  text-align:center;
  margin-bottom:1rem;
  font-size:20px;
  color:var(--app-theme);
  font-family: titillium web !important;
  font-weight: 400 !important;
}

.filter_dialogue .MuiDialog-paperFullWidth
{
  min-width: 30%;
  max-width: 40% !important;
  width: auto;
}

.advance_filter_main_div .formdiv .labeltxt
{
  color:var(--app-theme) !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
.advance_filter_main_div .ant-select-single.ant-select-open,.advance_filter_main_div .ant-select-selection--single
{
  color: #707070;
}
.advance_filter_main_div .formdiv .ant-select-selection--single
{
  border-right : 0px !important;
  border-top : 0px !important;
  border-left : 0px !important;
  box-shadow:none !important
}
.advance_filter_main_div .ant-select-selection--single
{
  padding-left: 0px !important;
}
.advance_filter_main_div .ant-select-selection--single .ant-select-selection__rendered
{
  margin-left: 0px !important;
}
.button_container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal_head
{
  color: white;
}

.arabic_cls .advance_filter_main_div .formdiv .labeltxt {
text-align: right;

}