.pro_headers {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile_popup_details .MuiDialog-paperWidthSm {
  width: 700px !important;
}

.profile_popup_details {
  font-family: "Roboto";
}

.addtrainees_details {
  font-family: "Roboto";
  display: flex;
  justify-content: space-between;
}

.prof_edit .MuiDialogTitle-root {
  border-bottom: 0px;
  box-shadow: none;
}

.details_mode {
  color: #ccc;
  font-family: "Roboto";
  font-size: 2rem !important;
  margin-bottom: 0rem !important;
}

.age_details {
  color: #ccc;
  font-size: 1.25rem;
  margin-bottom: 0px !important;
  display: inline-flex;
  width: 100%;
}

.arabic_cls .age_details {
  color: #ccc;
  font-size: 1.25rem;
  margin-bottom: 0px !important;
  display: inline-flex;
  width: 100%;
  direction: rtl;
}

.package_details {
  display: flex;
  color: #ccc;
  font-size: 15px;
  width: 50%;
  font-family: "Roboto";
  justify-content: space-between;
}

.avatar-uploader img {
  height: 100px;
  width: 100px;
}

.package_cancel_details {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}

.package-cancel_button {
  color: red !important;
  border: 1px solid red !important;
  font-size: 20px !important;
  font-family: "Roboto" !important;
  background-color: #f5dcead1 !important;
}

.profile_imageuser_container {
  display: flex;
  width: 100%;
  height: 100%;
}

.profile_imageuser {
  width: 360px;
  height: auto;
  object-fit: cover;
}

.profile_imageuser_div {
  width: 100%;
  height: 355px;
  width: 355px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.divider {
  padding: 20px !important;
}

.addprofile_gridcontainer {
  padding: 20px;
  padding: 10px !important;
}

.package_details_list {
  width: 80%;
  display: inline-flex;
  align-items: center;
  color: black;
  font-family: "Roboto" !important;
  font-size: 1.25px;
}

.package_details_container {
  display: flex;
  width: 100%;
  font-family: "Roboto";
}

.profile_detail {
  padding-bottom: 1%;
}

.working_hour {
  font-size: 13px;
  padding-bottom: 5%;
  padding-top: 5%;
}

.working_hour_detail {
  font-size: 11px;
  width: 100%;
}

.working_time_detail {
  padding-left: 10%;
  width: 100%;
}

.working_detail {
  display: inline-flex;
  width: 100%;
}

.profile_icon_edit {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  margin-top: 5%;
}

.area_services {
  font-size: 1.5rem !important;
  color: black;
  margin-bottom: 7px !important;
}

.areaservice_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.25rem;
  color: #ccc;
  width: 80%;
}

.icon {
  font-size: 2.25rem !important;
  fill: var(--app-theme) !important;
  margin-bottom: 3%;
}

.icon_groups {
  font-size: 2.25rem !important;
  fill: #00e1d2 !important;
}

.total {
  padding: 3%;
  width: 100%;
}

.border_para {
  border: 1px solid #ccc;
  height: 20px;
}

.profile_nursecontainer {
  font-family: "Roboto" !important;
  margin-top: -4%;
}

.profile_text {
  padding-left: 3%;
  width: 100%;
  position: relative;
  color: #808080;
  font-size: 14px;
  top: 1px
}

.profilebackground {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 5px !important;
  width: 80%;
  position: relative;
  top: -45px;
}

.profilepaper_container {
  display: flex;
  align-items: center;
  justify-content: center;
  top: -188px;
  position: relative;
}

.profileback_first {
  background: var(--app-theme);
  opacity: 1;
  width: 100%;
  height: 50%;
  color: #FFFF;
  font-size: 22px;
  font-weight: 500;
  padding: 25px 0px 0px 20px;
  font-family: titillium web !important;
}

.profile_spinner_align {
  top: 150px !important;
  height: 50px !important;
}

.profile_spinner_align .ant-spin-dot-item {
  background-color: var(--app-theme) !important;
}

.profile_background {
  height: 650px;
}

.profile_backchange {
  width: 50% !important;
  height: 350px;
}

.pic_align {
  position: absolute;
  bottom: 0px;
  right: 7px;
  top: 33px;
  background: #510F30;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  text-align: center;
  justify-content: center;
  border: 2px solid white;
}

.add_icon {
  font-size: 1rem !important;
  color: white;
  position: relative;
  top: -3px;
  right: 0.5px;
  margin-top: 3px;
}

.add_icon_profile {
  font-size: 1rem !important;
  margin-top: -5px;
  color: white;
}

.basic_head_border {
  color: #fff;
  margin-left: 20px;
  font-size: 22px;
  font-family: titillium web !important;
  border-bottom: 3px solid #fff;
  padding-bottom: 3px;
  cursor: pointer;
}

.basic_head {
  color: #fff;
  margin-left: 20px;
  font-size: 22px;
  font-family: titillium web !important;
  padding-bottom: 3px;
  cursor: pointer;
}

.profile_container .ant-upload {
  position: relative;
}

.vendor_name {
  font-size: 30px;
  font-weight: 500;
  color: #666666 !important;
}

.arabic_cls .vendor_name {
  text-align: end;
}

.arabic_cls .profileback_first {
  text-align: end;
  padding: 25px 20px 0px 0px;
}

.arabic_cls .year_edit {
  padding-right: 5px;
  padding-left: 0px;
}

.arabic_cls .started_since {
  justify-content: end;
}

.arabic_cls .age_details h5 {
  order: 1
}

.arabic_cls .age_details .profile_text {
  order: 0;
  padding-left: 0;
  padding-right: 3%;
  text-align: right;
}

.arabic_cls .working_detail,
.arabic_cls .working_hour {
  text-align: end;
}

.arabic_cls .basic_working_hour_detail {
  order: 1;
}

p {
  margin-top: 0;
  margin-bottom: 1rem !important;
}

.ar_camera_icon {
  left: 7px;
  right: unset;
}

.termsConditionsHeader {
  position: absolute;
  bottom: 30px;
}
.termsConditionsHeaderAR {
  position: absolute;
  bottom: 30px;
  width: 50%;
  display: flex;
  justify-content: end;
}
.termsConditions {
  font-size: 14px;
  border-bottom: 3px solid;
  font-weight: bold;
  color: #f49c7a;
  display: inline;
  cursor: pointer;
}