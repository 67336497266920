.addmanage_nurses_details {
  font-family: "Roboto";
  display: flex;
  justify-content: space-between;
}

.details_mode {
  color: #ccc;
  font-family: "Roboto";
  font-size: 2rem !important;
  margin-bottom: 0rem !important;
}

.age_details {
  color: #ccc;
  font-size: 12px;
  margin-bottom: 0px !important;
  display: inline-flex;
  width: 100%;
}

.package_details {
  display: flex;
  color: #ccc;
  font-size: 15px;
  width: 50%;
  font-family: "Roboto";
  justify-content: space-between;
}

.manage_nurse_image_container {
  display: flex;
  width: 100%;
  height: 100%;
}

.manage_nurse_image {
  width: 100%;
  height: 97%;
}

.manage_nurse_image_div {
  width: 100%;
  height: auto;
}

.manage_nurse_image_div img {
  width: 100%;
}

.divider {
  padding: 20px !important;
}

.MuiDivider-root {
  height: 2px !important;
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}

.addmanage_nurse_gridcontainer {
  padding: 20px;
}

.package_details_list {
  width: 100%;
  display: inline-flex;
  align-items: center;
  color: black;
  font-family: "Roboto" !important;
  font-size: 11px;
}

.package_details_container {
  display: flex;
  width: 100%;
  font-family: "Roboto";
}

.manage_nurse_detail {
  text-align: left;
  font-size: 24px;
  font-weight: 500;
}

.manage_nurse_detail h1 {
  font-size: 18px !important;

}

.nurse_name_edit {
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: black;
}

.exp_text_edit {
  font-size: 15px;
}

.manage_nurse_popup_details .working_hour {
  font-size: 16px;
  color: black;
  font-weight: 400;
}

.manage_nurse_popup_details .nurse_working_hour_detail {
  font-size: 14px;
  color: black;
  font-weight: 400;
  width: 72%;
}

.manage_nurse_popup_details .working_time_detail {
  width: 100%;
  font-size: 14px;
  padding-left: 0px !important;
  font-weight: 400;
  color: #707070;
}

.manage_nurse_popup_details .working_time_detail_skills {
  padding-left: 0px !important;
  font-size: 1vw;
}

.manage_nurse_popup_details .nurse_working_detail {
  display: inline-flex;
  width: 92%;
}

.icon_edit {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.icon {
  font-size: 2.25rem !important;
  fill: var(--app-theme) !important;
  margin-bottom: 3%;
}

.total {
  padding: 3%;
  width: 100%;
}

.manage_nurse_text {
  padding-left: 3%;
  width: 100%;
  color: grey;
}

.nurse_name_edit .p {
  margin-bottom: 0px
}

.location_icon {
  font-size: 1.75rem !important;
}

.call_icon {
  font-size: 1.75rem !important;
}

.globe_icon {
  font-size: 1.75rem !important;
}

.nurse_modal_button {
  display: flex;
  justify-content: space-around;
}

.manage_nurse_close_icon {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  padding-right: 10px;
  cursor: pointer;

}

.eight_hrs_edit {
  height: 31px;
  width: 74px;
  border-radius: 16px;
  background-color: var(--app-theme);
  color: white;
  font-size: 15px;
  text-align: center;
  margin-bottom: 7px;
  margin-left: 15px;
}

.eight_text {
  padding-top: 5px;
}

.cost_edit {
  font-size: 14px !important;
  margin: 0px;
  color: #707070;
}

.btn_text {
  text-align: center;
  font-size: 14px;
  color: #707070;
}

.labelboxabc {
  font-size: 12px;
}
.new_ovwer_flow {
  height: 550px !important;
  /* overflow-y: scroll !important;
  overflow-x: hidden !important; */
}
