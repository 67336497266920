.title_dashboard {
  width: 100%;
  padding: 5px 15px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px 5px 0px 0px;
  height: 60px;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title_dashboard .formdiv {
  display: flex;
  align-items: baseline;
}

.title_dashboard .formdiv .labeltxt {
  /* width: 45%; */
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.title_dashboard .formdiv .selectbox {
  width: 50% !important;
}

.title_header {
  font-size: 22px;
  color: var(--app-theme) !important;
  /*margin-left: 2%;
  margin-top: 10px;*/
  font-family: titillium web !important;
}

.title_header span {
  font-family: titillium web !important;
}

.today_date {
  color: rgba(0, 0, 0, 0.87) !important;
  margin-right: 15px !important;
}

.clinic_group_details .MuiButton-root {
  text-transform: none !important;
  border-radius: 0px !important;
  font-size: 12px !important;
  padding: 5px 15px !important;
  border: none !important;
  box-shadow: 0px 2px 5px #00000033 !important;
}

.clinic_details:focus {
  background-color: #0B51C5 !important;
}

.clinic_details {
  color: white !important;
  background-color: #468DF0 !important;
  width: 120px !important;
  height: 42px !important;
  font-family: "Roboto" !important;

}


.icon_head {
  display: flex;
  padding-right: 3px;
  /* align-items: end !important; */
  gap: 15px;
}

@media screen and (max-width:990px) {
  .mr-2 {
    font-size: 12px;
  }

}


/* toaster */
/* 
.ant-notification-notice-closable{
  padding: 14px 5px 0px 16px;
} */
.ant-notification-notice-description {
  position: relative;
  bottom: 17px;
  margin-right: 40px;
}

.MuiDialog-paperScrollPaper {
  overflow-y: scroll !important;
}

.title_dashboard .rangePicker_Calendericon {
  bottom: 0px !important;
}

.arabic_cls .title_header {
  order: 1;
  text-align: right;
}


/* Arabic css start  */

.arabic_cls .upload_ins {
  margin-left: 0px;
  margin-right: 10px;

}

.order_0 {
  order: 0;
}

.order_1 {
  order: 1;
}

.order_2 {
  order: 2;
}

.order_3 {
  order: 3;
}

.order_4 {
  order: 4;
}

.order_5 {
  order: 5;
}

.order_6 {
  order: 6;
}

.order_7 {
  order: 7;
}

.order_8 {
  order: 8;
}

.order_9 {
  order: 9;
}

.order_10 {
  order: 10;
}

.order_11 {
  order: 11;
}

.order_12 {
  order: 12;
}

.order_13 {
  order: 13;
}

.order_14 {
  order: 14;
}

.order_15 {
  order: 15;
}

.order_16 {
  order: 16;
}

.order_17 {
  order: 17;
}

.order_18 {
  order: 18;
}

.order_19 {
  order: 19;
}

.order_20 {
  order: 20;
}

.arabic_cls .diet_titleuser {
  order: 1;
}

.arabic_cls .rangePicker_Calendericon {
  z-index: 999;
  right: -11px !important;
}

.arabic_cls .rangePicker_Master .brdrcls {
  padding: 4px 4px 4px 30px !important;
}

.arabic_cls .rangePicker_Label {
  margin-left: 0px !important;
}

.arabic_cls .daterangeMaster {
  right: auto;
}

.arabic_cls .dashboard_title,
.arabic_cls .appointment_titleuser,
.arabic_cls .titleuser {
  order: 1;
}

.arabic_cls .filter_div {
  margin-left: 15px;
}

.arabic_cls .manage_title {
  order: 1;
}

.arabic_cls .table_sec_sel {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-left-radius: 25px !important;
  border-bottom-left-radius: 25px !important;
}

.arabic_cls .table_fir_sel {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.arabic_cls .Dialogmodal h6 {
  text-align: end !important;
}

.icon_head {
  display: flex;
  padding-right: 3px;
  align-items: center;
  gap: 15px;
}