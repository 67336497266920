.option_container{
  background-color: #FFFFFF;
  display:flex;
  justify-content: space-between;
  align-items: center; 
  padding:10px; 
  font-size: 22px;
  padding:15px 25px 15px 25px; 
}
.option_container p{
  margin-bottom: 0px !important;
}
.select_text{
color:#707070;
font-size: 22px;
}
.option_amt{
  color:var(--app-theme);
  font-weight: 600;
}
.cancelpay_button{
background-color: #FFFFFF !important;
border: 1px solid var(--app-theme) !important;
border-radius: 0px !important;
font-size: 22px !important;
text-transform: none !important;
color:var(--app-theme) !important;
font-weight: 600 !important;
}
.payment_method_container{
  background-color: #FFFFFF;
 
  padding:15px 0px 15px 0px; 
  font-size: 22px;
  margin-top: 10px;

}
.payment_method_container p{
  margin-bottom: 0px !important;
}
.select_pay_text{
  color:var(--app-theme) !important;
  padding:15px 0px 15px 25px; 
}
.select_divider{

 height:1px !important;
 margin-bottom: 25px !important;
}
.saved_cards_container{
  padding:0px 0px 0px 15px;
}
.saved_cards{
 color:#707070;
}
.payment_method{
  font-size: 18px;
  color:#707070 !important;
  border-right: 2px dashed rgba(0, 0, 0, 0.08);
  display:flex;
  margin: 10px 0px 0px 0px;
}
.payment_method p{
  margin-bottom: 20px !important;
}
.divider_line{
  border-left:2px solid  rgba(0, 0, 0, 0.08);
  margin:0px 5px 0px 5px;

}
/* .PaymentMethod .formdiv .brdrcls{
   background: #FFFFFF 0% 0% no-repeat padding-box;
   box-shadow: 0px 0px 6px #00000024;
   border: 0px !important;
} */
.PaymentMethod .formdiv .selectbox .ant-select-selection--single{
  font-size:12px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 6px #00000024 !important;
      border: 0px !important;
}
.PaymentMethod .formdiv .brdrcls{
  font-size:12px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0 0 6px 0px #ccc !important;
      border: 0px !important;
}
.select_expiry_date{
display: flex;
}
.select_labelbox {
  position: relative;
  top: 22px;
  width: 49%;
  padding-left: 10px;
}
/* .background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 0px 6px #00000024;
border-radius: 3px;
opacity: 1; */
.summery_text{
  font-size: 20px;
  color:var(--app-theme) !important;
  display:flex;
  align-items: center;
  justify-content: center;
}
.sub_total_div{
  display:flex;
  font-size: 20px;
  color:#707070;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px 10px 0px;

}
.summery_div{
  padding: 20px 15px
}
.pay_now_button{
  background-color:var(--app-theme) !important;
  color:#FFFF !important;
  font-family: "Roboto" !important;
  font-size: 22px !important;
  height:50px !important;
  width: 140px !important;
  text-transform: none !important;
}
.pay_now_container{
  display:flex;
  justify-content: flex-end;
  align-items: center;
  padding:20px 20px;
}
.payment_method_list{
  width:100%;
  padding: 10px;
}
.saved_cards_parent{
  display:flex;
  align-items:center;
  justify-content: space-around;
  padding-bottom: 15px;
}
.deb_img{
  width:100%;
  height:100%;
}
.saved_cards_parent{
  width:100%;
  height:100%;


}
.debit_child{
  width:90%;
  height:auto;
}
.debit_container{
  display:flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.pay_carddetails{
  position: absolute;
}
.payment_method_child{
margin-bottom: 20px !important;
padding-right:20px !important;
}
.payment_method p:active{
color:var(--app-theme) !important;
}
.pay_carddetails h1{
  font-size: 32px;
  color: white;
  margin-bottom: 15px;
}
.pay_carddate{
  font-size: 13px;
  color: white;
}
.pay_carddetails h4{
  color: white;
  margin-top: 18px;
}
.PaymentMethod .formdiv .brdrcls{
 padding:9px !important;
}
.PaymentMethod .ant-tabs-nav-container{
border-bottom: 0px !important;
margin:0px !important;
}
.card_move{
cursor:pointer;
}
.PaymentMethod  .ant-tabs-nav-wrap{
  margin-bottom: 0px !important;
}
.PaymentMethod  .ant-tabs .ant-tabs-left-content{
  padding-left: 10px !important;
}
.PaymentMethod  .ant-tabs-ink-bar
{
  background-color: var(--app-theme) !important;
}
.PaymentMethod .ant-tabs-nav .ant-tabs-tab:active{
  color: var(--app-theme) !important;
}
.ant-tabs-nav .ant-tabs-tab-active{
  color:var(--app-theme) !important;
}
