.cell_eye {
  padding-left: 0px !important;
}
.idel_nurse_table_div table thead tr th,
.idel_nurse_table_div table tbody tr th,
.idel_nurse_table_div table tbody tr td
{
  text-align: left;
}
.idel_nurse_table_div table tbody tr td:nth-child(1){
  text-align: center !important;
}
.idel_nurse_table_div table thead tr th:nth-child(4),
.idel_nurse_table_div table tbody tr td:nth-child(4),
.idel_nurse_table_div table thead tr th:nth-child(5),
.idel_nurse_table_div table tbody tr td:nth-child(5),
.idel_nurse_table_div table thead tr th:nth-child(6),
.idel_nurse_table_div table tbody tr td:nth-child(6),
.idel_nurse_table_div table thead tr th:nth-child(7),
.idel_nurse_table_div table tbody tr td:nth-child(7),
.idel_nurse_table_div table thead tr th:nth-child(8),
.idel_nurse_table_div table tbody tr td:nth-child(8),
.idel_nurse_table_div table thead tr th:nth-child(9),
.idel_nurse_table_div table tbody tr td:nth-child(9)
{
  text-align: center;
  justify-content: center !important;
} 

.idel_nurse_table_div table thead tr th:nth-child(4) span svg,
.idel_nurse_table_div table thead tr th:nth-child(5) span svg,
.idel_nurse_table_div table thead tr th:nth-child(7) span svg,
.idel_nurse_table_div table thead tr th:nth-child(8) span svg{
  display: none;
}

.react-datepicker__month-.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header
{
  font-size: 13px !important;

}
.react-datepicker__month
{
  font-size: 13px;
}
.react-datepicker-wrapper
{
  width: auto !important;
}
.react-datepicker-wrapper input
{
  width: 100px !important;
  margin-right: 15px;
  outline: 0;
  font-size: 13px;
  box-shadow: 0 0 1.5px 0px #ccc;
  border: 1px solid #ccc;
  border-radius: 2px !important;
  font-weight: 500 !important;
  height: 30px;
  padding-left: 10px;
}

.arabic_cls .idel_nurse_table_div table thead tr th:nth-child(8),
.arabic_cls .idel_nurse_table_div table tbody tr th:nth-child(8),
.arabic_cls .idel_nurse_table_div table thead tr th:nth-child(5),
.arabic_cls .idel_nurse_table_div table tbody tr td:nth-child(5),
.arabic_cls .idel_nurse_table_div table thead tr th:nth-child(4),
.arabic_cls .idel_nurse_table_div table tbody tr td:nth-child(4),
.arabic_cls .idel_nurse_table_div table thead tr th:nth-child(3),
.arabic_cls .idel_nurse_table_div table tbody tr td:nth-child(3),
.arabic_cls .idel_nurse_table_div table thead tr th:nth-child(2),
.arabic_cls .idel_nurse_table_div table tbody tr td:nth-child(2),
.arabic_cls .idel_nurse_table_div table thead tr th:nth-child(1),
.arabic_cls .idel_nurse_table_div table tbody tr td:nth-child(1)
{
  text-align: center;
  justify-content: center !important;
} 

.arabic_cls .idel_nurse_table_div table thead tr th:nth-child(6),
.arabic_cls .idel_nurse_table_div table tbody tr td:nth-child(6),
.arabic_cls .idel_nurse_table_div table thead tr th:nth-child(7),
.arabic_cls .idel_nurse_table_div table tbody tr td:nth-child(7)
{
  text-align: right;
}
.react-datepicker__input-container .react-datepicker__calendar-icon{
  display: none !important;
}
.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 9px !important;
}