.fa-user-plus {
  font-family: "FontAwesome" !important;
}

.User-inputs {
  margin-bottom: 15px;
  width: 115%;
}

.gender_edit {
  width: 115%;
}

.users-form {
  padding-left: 12px !important;
}

.user-avatar-upload {
  display: flex !important;
  justify-content: center !important;
  margin-top: 2px !important;
}

.Usergrp-Create {
  color: #fff !important;
  font-size: 16px !important;
  margin-left: 10px !important;
  background-color: var(--app-theme) !important;
  font-family: "Roboto" !important;
  border-radius: 50px !important;
  padding: 6px 16px !important;
  text-transform: capitalize !important;
}

.Usergrp-Cancel {
  color: #333 !important;
  font-size: 16px !important;
  margin-right: 10px !important;
  background-color: rgb(201, 201, 201) !important;
  font-family: "Roboto" !important;
  border-radius: 50px !important;
  padding: 6px 16px !important;
  text-transform: capitalize !important;
}

.users-form .ant-upload.ant-upload-select-picture-card {
  width: 120px !important;
  border-radius: 50% !important;
  border: none !important;
  height: 120px !important;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12) !important;
}

.user_datepicker {
  width: 100%;
  border: none;
  outline: 0;
  box-shadow: 0 0 1.5px 0px #ccc;
}

.nurse_upload_icon {
  font-size: 55px !important;
  color: var(--app-theme);
  margin-left: 21px !important;
  margin-top: -2px;
}

.dateofbirth {
  color: #333;
  display: block;
  padding-bottom: 10px;
  font-size: 15px;
}

.users-form .ant-input {
  border-radius: 0px !important;
  border: none !important;
  font-family: "Roboto" !important;
}

img.upload-img-circle {
  width: 85px !important;
  height: 85px !important;
  border-radius: 50% !important;
}

.Textarea_grid {
  padding: 0px !important;
}

.cancel-create-button1 {
  margin-left: 25px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.users-form .formdiv .brdrcls {
  padding: 6px !important;
  border: 1px solid #ccc !important;
  font-size: 12px !important;
  font-weight: 100;
  color: #707070 !important;
  font-family: "Roboto" !important;
}

.users-form .formdiv .MuiInputBase-input {
  font-size: 12px !important;
  font-weight: 100;
  color: #707070 !important;
}

.users-form .formdiv {
  margin-bottom: 10px;
}

.buttons_container {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 98%;
}

.users-form .formdiv .labeltxt {
  padding-bottom: 0px !important;
  font-weight: 400;
  font-size: 14px !important;
  color: black !important;
}

.users-form .formdiv .selectbox .ant-select-selection--single {
  padding: 2px !important;
}

.items_container {
  padding: 5px;
}

.new_ovwer_flow {
  height: 500px !important;
   overflow-y: scroll !important;
  overflow-x: hidden !important; 
}

.chkbox_text_edit {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.manage_active_font {
  font-size: 15px;
}

.nurse_details_nation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.duty_hourmain_container {
  border: 1px solid #ccc;
  display: flex;
  border-radius: 2px;
  justify-content: space-between;
  width: 115%;
  padding: 18px;
}

.duty_hoursdiv {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 100px;
  align-items: center;
  padding: 10px;
}

.duty_hour_container {
  width: 100%;
  padding: 11px;
}

.divider_border {
  border-left: 1px solid #ccc;
  margin: 3px 5px 0px 0px;
}

.skills .formdiv .brdrcls {
  height: 65px !important;
}

.duty_header {
  font-size: 1.5rem !important;
}

.user_buttons_container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.5rem !important;
}

.cancel_button {
  background-color: rgb(201, 201, 201) !important;
  font-size: 1.25rem !important;
  font-family: "Roboto" !important;
}

.Upload_button {
  background-color: rgb(38, 128, 235) !important;
  font-size: 1.25rem !important;
  font-family: "Roboto" !important;
}

@media only screen and (max-width: 959px) {
  .cancel-create-button1 {

    margin-left: 0px;
  }
}

.User-upload-container {
  display: block;
}

.User-upload-container .MuiGrid-grid-xs-2 {
  max-width: 0% !important;
}

.users-form .user_upload_info {
  width: 100%;
  display: flex;
  justify-content: end
}

.MuiPickersToolbar-toolbar {
  background: var(--app-theme) !important;
  border-radius: 25px 25px 0px 0px;
  height: unset !important;
}

.MuiButton-textPrimary {
  color: #510F30 !important;
}

.MuiTypography-colorPrimary {
  color: #510F30 !important;
}

.activecheackboxchecked:checked {
  background-color: var(--app-theme) !important;
}

.e-checkbox-wrapper .e-frame.e-check {
  color: yellow !important;
}

.nurse_popup_main_div .formdiv .brdrcls {
  padding: 9px;
  font-size: 14px;
  color: #707070;
  width: 95%;
}

.brdred {
  border-color: red !important;
}

.formdiv .brdred {
  border: 1px solid red;
  margin-bottom: 10px;
  border-color: #ff0068 !important;
}

.nurse_popup_main_div .formdiv .selectbox .ant-select-selection--single {
  font-size: 14px;
  color: #707070;
  width: 100%;
}

.nurse_popup_main_div .formdiv .MuiInputBase-root {
  font-size: 14px;
  color: #707070;
  height: 39px;
  width: 95%;
}

.sub_label {
  font-size: 13px;
  color: #707070;
  float: right;
  margin-top: 3px;
}

.arabic_cls .sub_label {
  float: left;
}

.nurse_image_container .ant-upload.ant-upload-select {
  background-color: #fff !important;
  border: 0px !important;
  width: 85px !important;
  border-radius: 50% !important;
  border: none !important;
  position: relative;
  height: 85px !important;
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%) !important;
}

.arabic_cls .nurse_pic_align {
  right: unset;
}

.nurse_pic_align {
  position: absolute;
  bottom: 0px;
  right: 0px;
  top: 60px;
  background: #510F30;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  text-align: center;
  justify-content: center;
}

.nurse_image_container {
  position: absolute;
  z-index: 9;
  left: 45%;
  top: 15px;
}

.nurse_info_icon {
  position: absolute;
  right: -30px;
  top: 63px;
}

.arabic_cls .nurse_popup_main_div .formdiv .brdrcls,
.arabic_cls .nurse_popup_main_div .formdiv .selectbox .ant-select-selection--single {
  display: flex;
  justify-content: end;
}

.arabic_cls .formdiv .Errormsg {
  text-align: right;
}

.arabic_cls .nurse_popup_main_div .formdiv .selectbox .ant-select-selection--single {
  width: 100% !important;
}

.arabic_cls .nurse_popup_main_div .formdiv .MuiInputBase-root {
  margin-left: auto;
}

.MuiPickersToolbarText-toolbarTxt {
  color: white;
  font-size: 13px;

}
/* .Dialogmodal .MuiDialogContent-dividers{
    overflow: auto !important;
  } */

.arabic_cls .nurse_popup_main_div .formdiv .selectbox .ant-select-selection--single{
  width: 100% !important;
}
/* .nurse_popup_main_div .new_ovwer_flow  */

.MuiPaper-root .MuiDialog-paper .MuiDialog-paperScrollPaper .MuiDialog-paperWidthMd .MuiDialog-paperFullWidth .MuiPaper-elevation24 .MuiPaper-rounded{
  overflow: auto !important;
}