.notification_popup {
  width: 350px;
  overflow-y: auto;
}

.noti-row {
  padding: 2%;
  font-size: 14px;
}

.no-notifi-text {
  padding: 4%;
  font-size: 14px;
  color: #707070;
}
