.cell_eye {
  padding-left: 0px !important;
}

.canceled_appointment_table_div table thead tr th:nth-child(4),
.canceled_appointment_table_div table tbody tr td:nth-child(4),
.canceled_appointment_table_div table thead tr th:nth-child(5),
.canceled_appointment_table_div table tbody tr td:nth-child(5)
{
  text-align: center;
}

.arabic_cls .canceled_appointment_table_div table thead tr th:nth-child(2),
.arabic_cls .canceled_appointment_table_div table tbody tr td:nth-child(2),
.arabic_cls .canceled_appointment_table_div table thead tr th:nth-child(1),
.arabic_cls .canceled_appointment_table_div table tbody tr td:nth-child(1),
.arabic_cls .canceled_appointment_table_div table thead tr th:nth-child(5),
.arabic_cls .canceled_appointment_table_div table tbody tr th:nth-child(5)
{
  text-align: center;
}

.arabic_cls .canceled_appointment_table_div table thead tr th:nth-child(3),
.arabic_cls .canceled_appointment_table_div table tbody tr td:nth-child(3),
.arabic_cls .canceled_appointment_table_div table thead tr th:nth-child(4),
.arabic_cls .canceled_appointment_table_div table tbody tr td:nth-child(4)
{
  text-align: right;
}