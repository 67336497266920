.start_icon_half {
  font-size: 22px !important;
}

.start_icon_full {  
  font-size: 22px !important;
  fill: var(--pink-color) !important;
}

.review_report_div table thead th:nth-child(7),
.review_report_div table thead th:nth-child(8),
.review_report_div table tbody td:nth-child(7),
.review_report_div table tbody td:nth-child(8) {
  text-align: center;
}

.review_report_div table tbody tr td,
.review_report_div table tbody tr th {
  color: #707070 !important;
}

.nurse_dashboard_buttons_wrap {
  padding-top: 25px;
  margin-top: 5px;
  display: flex;
  justify-content: space-around;
}



.review_report_button_common_styles {
  display: block;
  justify-content: center;
  height: 105px;
  width: 18%;
  border-radius: 7px !important;
  color: #ffffff !important;
  /* font-family: "Roboto" !important; */

  font-size: 1.5rem !important;
  cursor: pointer;
}

.nurse_button_text {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
  color: #fff;
  font-family: Roboto;
}

.divider_container {
  display: flex;
  justify-content: center;
}

.divider_1px {
  height: 0.5px;
  border-radius: 20%;
  width: 85%;
  background-color: #ffffff;
}

.nurse_dash_numeric_wrap {
  margin-top: 7px;
  display: flex;
  justify-content: center;
  color: #fff;
}

.nurse_dash_numeric_value {
  font-size: 3rem;
}

.review_report_button1 {
  background: #510F30 0% 0% no-repeat padding-box !important;
}

.review_report_button2 {
  background: #00A49A 0% 0% no-repeat padding-box !important;
}

.review_report_button3 {
  background: #00E8D2 0% 0% no-repeat padding-box !important;
}

.review_report_button4 {
  background: #FF0068 0% 0% no-repeat padding-box !important;
}

.review_report_button5 {
  background: #F49C7A 0% 0% no-repeat padding-box !important;
}


.nurse_dashboard_buttons_wrap .card-hghlght,
.card-normal {
  opacity: 1;
  transform: scale(1.02);
}

.nurse_dashboard_buttons_wrap .card-deselect {
  opacity: 0.5;
  transform: scale(0.9);
}

.review_Avatar {
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
  margin-right: 10px;
}

@media only screen and (max-width: 1060px) {
  .nurse_button_common_styles {
    height: 100px;
    width: 140px;
    text-decoration: none !important
  }

  .nurse_dash_numeric_wrap {
    margin-top: 4px;
  }
}

@media only screen and (max-width: 830px) {
  .nurse_button_common_styles {
    height: 90px;
    width: 120px;
    text-decoration: none !important
  }

  .nurse_button_text {
    margin-top: 7px;
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
  }

  .nurse_dash_numeric_wrap {
    margin-top: 3px;
  }
}

.review_report_div table thead tr th:nth-child(4),
.review_report_div table tbody tr td:nth-child(4),
.review_report_dashboard_div table thead tr th:nth-child(1),
.review_report_dashboard_div table tbody tr th:nth-child(1),
.review_report_dashboard_div table thead tr th:nth-child(4),
.review_report_dashboard_div table tbody tr td:nth-child(4),
.review_report_dashboard_div table thead tr th:nth-child(5),
.review_report_dashboard_div table tbody tr td:nth-child(5),
.review_report_dashboard_div table thead tr th:nth-child(6),
.review_report_dashboard_div table tbody tr td:nth-child(6),
.review_report_dashboard_div table thead tr th:nth-child(7),
.review_report_dashboard_div table tbody tr td:nth-child(7),
.review_report_dashboard_div table thead tr th:nth-child(8),
.review_report_dashboard_div table tbody tr td:nth-child(8) {
  text-align: center;
  justify-content: center !important;
}

.review_report_dashboard_div table tbody tr td {
  color: #707070 !important;
  font-size: 14px;
  font-weight: 400;
}


.arabic_cls .review_report_div table thead th:nth-child(2),
.arabic_cls .review_report_div table tbody td:nth-child(2),
.arabic_cls .review_report_div table thead th:nth-child(1),
.arabic_cls .review_report_div table tbody td:nth-child(1),
.arabic_cls .review_report_div table thead th:nth-child(8),
.arabic_cls .review_report_div table tbody th:nth-child(8) {
  text-align: center;
}

.arabic_cls .review_report_div table thead th:nth-child(3),
.arabic_cls .review_report_div table tbody td:nth-child(3),
.arabic_cls .review_report_div table thead th:nth-child(4),
.arabic_cls .review_report_div table tbody td:nth-child(4),
.arabic_cls .review_report_div table thead th:nth-child(5),
.arabic_cls .review_report_div table tbody td:nth-child(5),
.arabic_cls .review_report_div table thead th:nth-child(6),
.arabic_cls .review_report_div table tbody td:nth-child(6),
.arabic_cls .review_report_div table thead th:nth-child(7),
.arabic_cls .review_report_div table tbody td:nth-child(7) {
  text-align: right;
}

.arabic_cls .review_report_dashboard_div table thead tr th:nth-child(8),
.arabic_cls .review_report_dashboard_div table tbody tr th:nth-child(8),
.arabic_cls .review_report_dashboard_div table thead tr th:nth-child(5),
.arabic_cls .review_report_dashboard_div table tbody tr td:nth-child(5),
.arabic_cls .review_report_dashboard_div table thead tr th:nth-child(4),
.arabic_cls .review_report_dashboard_div table tbody tr td:nth-child(4),
.arabic_cls .review_report_dashboard_div table thead tr th:nth-child(3),
.arabic_cls .review_report_dashboard_div table tbody tr td:nth-child(3),
.arabic_cls .review_report_dashboard_div table thead tr th:nth-child(2),
.arabic_cls .review_report_dashboard_div table tbody tr td:nth-child(2),
.arabic_cls .review_report_dashboard_div table thead tr th:nth-child(1),
.arabic_cls .review_report_dashboard_div table tbody tr td:nth-child(1) {
  text-align: center;
}

.arabic_cls .review_report_dashboard_div table thead tr th:nth-child(6),
.arabic_cls .review_report_dashboard_div table tbody tr td:nth-child(6),
.arabic_cls .review_report_dashboard_div table thead tr th:nth-child(7),
.arabic_cls .review_report_dashboard_div table tbody tr td:nth-child(7) {
  text-align: right;
}
.left_arrow_dashboard{
  cursor: pointer;
  font-size: 4rem !important;
  fill: black !important;
  margin-top: -3px;
}