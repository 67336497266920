.printtabledata {
    page-break-inside: avoid;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    /* Ensure the div takes up the full page height */
    box-sizing: border-box;
 }
 
 .page-break {
    display: block;
    page-break-before: always;
    break-before: page;
 }
 
 .print_footer {
    text-align: right;
    font-weight: bold;
    margin-top: auto;
    /* Push the footer to the bottom */
    /* margin-bottom: 10px; */
    /* Ensure there's some space from the bottom */
 }
 
 .tom_logo_print {
    display: block;
    margin-left: 0;
    /* margin-right: 0; */
    text-align: left !important;
 }
 
 .printDataTitle {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
 }
 
 .printtabledata table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 10px;
 }
 
 .printtabledata th,
 .printtabledata td {
    border: 1px solid #000;
    padding: 8px;
 }
 
 .print_header_text {
    background-color: #f2f2f2;
 }
 
 @media print {
    html,
    body {
        height: auto;
    }
    .page-break {
        display: block;
        page-break-before: always;
    }
 
    .printtabledata {
        page-break-inside: avoid;
        min-height: 90vh;
        /* Ensure the div takes up the full page height */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
 }
 .kwd_vlaues_font {
    display: flex !;
    text-align: end !important;
    justify-content: end !important;
    font-size: 12px !important;
 }