.Dialogmodal .MuiDialogContent-dividers{
border-bottom: 0px !important;
	border-top:0px !important;
	overflow: hidden;
}
.Dialogmodal h6{
    font-size: 21px;
    color:#fff;
    font-family: titillium web !important;
    font-weight: 400;
    text-transform: uppercase;
}
.modaldiv .cancelbtn{
	border-radius: 0;
	background-color: #f1221a;
	color:white;
	box-shadow: 0;
}

/* .Dialogmodal .MuiDialogTitle-root{ */
	/* box-shadow: 0px 0px 3.5px 0px #ccc; */
	/*border-bottom: 1px solid #ccc;*/
/* } */
.Dialogmodal .MuiIconButton-root:hover{
	background-color: transparent;
}
.Dialogmodal .closebtn{
	background-color: rgb(201, 201, 201);
    border-radius: 0px;
    color: rgb(255, 255, 255);
    text-transform: capitalize;
    /* padding: 9px; */
    margin-right: 10px;
    color: #000;
    font-size: 13px;
    width: 90px;
}
.Dialogmodal .createbtn{
	background-color: #2580eb;
    border-radius: 0px;
    color: rgb(255, 255, 255);
    text-transform: capitalize;
    /* padding: 9px; */
    margin-right: 10px;
    color: #fff;
    font-size: 13px;
    width: 90px;
}
.Dialogmodal .createbtn:hover {
    background-color: #2580eb;
}
/* .Dialogmodal .DialogContent{
    overflow-y: scroll;
} */
.modalcomp_closeicon{
    fill: #ff0068 !important;
    height: 25px !important;
    width: 25px !important;
    margin-top: 13px !important;
    cursor: pointer;
    right: 15px !important;
    top: 8px;
    color: #9e9e9e;
    position: absolute;
}

.nurse_location_modal_div .MuiPaper-root
{
   width: 50% !important;
   max-width: 50% !important;
}

.Dialogmodal .MuiDialogTitle-root {
    border-radius: 30px 30px 0px 0px;
    /* box-shadow: 0px 0px 3.5px 0px #ccc; */
    border-bottom: 1px solid #ccc;
    background: #510F30;
}
.Dialogmodal h6 .textclr
{
    font-family: titillium web !important;
    font-size: 22px;
    /* margin-left: 10px; */
}
.arabic_cls.Dialogmodal h6{
    text-align: end !important;
}
.arabic_cls .modalcomp_closeicon{
  left: 15px !important;
}
/* 
.videoModalam .MuiDialog-paperWidthXs {
    max-width: 980px !important;
    height: 81.5vh !important;
} */