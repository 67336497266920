.brdrcls .label-box {
  font-size: 1.5rem !important;
}

.Active_Checkbox-container {
  width: 100%;
  height: auto;
  margin-left: 4px;
}
.button {
  font-size: 1.5rem;
}
.button-container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}
.MuiTypography-body1 {
  font-family: "Roboto" !important;
  font-size: 1.5rem !important;
}
.Cancel-form {
  color: rgb(42, 40, 40) !important;
  font-size: 1.25rem !important;
  margin-right: 10px !important;
  background-color: #ccc !important;
  font-family: "Roboto" !important;
}
.Cancel-form:focus {
  outline: none !important;
}
.Upload {
  color: #fff !important;
  font-size: 1.25rem !important;
  margin-left: 10px !important;
  background-color: #1890ff !important;
  font-family: "Roboto" !important;
}
.Upload:focus {
  outline: none !important;
}
.uploadfiles-wrapper {
  margin-left: 20px;
}

.uploadfiles-wrapper .ant-upload {
  font-size: 1.5rem;
  font-family: "Roboto";
  cursor: text !important;
  color: rgb(151 151 151) !important;
  width: 100%;
}
.Button-container {
  font-size: 1.5rem !important;
  font-family: "Roboto" !important ;
  padding: 8px 8px !important;
  color: rgb(128, 128, 128) !important;
  width: 100% !important;
  text-transform: none !important;
}
.upload-button {
  box-sizing: border-box;
  box-shadow: 0 0 1.5px 0px #ccc;
  margin-top: 13px;
}
.Button-container:focus {
  outline: none !important;
}

.Upload-button {
  margin-bottom: 10px !important;
}
.upload-button .MuiButton-label {
  display: flex;
  justify-content: space-between;
}
.uploadimage-container {
  width: 20px;
  height: auto;
  display: flex;
  margin-left: 10px;
}
.ant-upload-list-item {
  font-size: 1.25rem;
}
.uploadimage {
  width: 100%;
  height: auto;
}
@media only screen and (max-width: 959px) {
  .ant-upload {
    margin-left: 0px !important;
  }
}

.modal-date {
  display: flex;
  justify-content: space-between;
  margin-left: 5px;
  margin-right: 5px;
}
.modal-text {
  margin-left: 25% !important;
  margin-top: 4% !important;
}
.hrsBtnNurse {
  float: right;
  margin-right: 2%;
  margin-top: 2%;
  padding-right: 24px !important;
  padding-left: 24px !important;
  border-radius: 42px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.Card-par-nurse {
  position: none ! important;
  width: 100%;
  padding: 3px 3px;
  overflow-x: scroll;
}
.avatar {
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: -18px;
  margin: auto;
  left: 38px !important;
}
.card-img {
  width: 60px;
  height: 63px;
  border-radius: 30px;
}
.card-profile {
  width: 100%;
  height: 190px;
}
.paper-bck {
  margin-top: 10px;
  background-color: lightgray !important;
  width: 100% !important;
  display: flex;
  justify-content: space-around;
  overflow:scroll;
}
.left_arrow_edits{
  font-size: 30px;
  display: flex;
  margin-top: 75px;
}
.right_arrow_edits{
  font-size: 30px;
  display: flex;
  margin-top: 70px;
}

.hrsbtn{
  cursor: auto !important;
}

.customer-txt{
  margin: 2px;
  font-size: 13px;
}

.nurseHistoryWidth{
  height:230px;
}

.curstomerservice{
  height: 40px;
  overflow-y: scroll;
  margin: 5px;
  display: flex;
  justify-content: center;
  text-align: center;
}
.nurseCardSpace{
  margin-bottom: 30px;
}