.tick_mark
{
    height: calc(100vh - 450px);
    width: auto;
    background-repeat: no-repeat;
}

.cross_mark{
    fill:red !important;
    font-size: 22rem !important;
}