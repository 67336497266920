.revenueclinic_container .formdiv {
   display: flex;
   align-items: center;
   margin-right: 15px;
}

.revenueclinic_container .formdiv .labeltxt {
   padding-right: 5px;
   padding-bottom: 0px !important;
   width: 80%;
}

.calendar_container {
   padding-bottom: 50px;
}

@media screen and (max-width: 959px) {

   .calendar_container {
      height: 100%;
   }
}

.clinic_label {
   font-size: 18px;
   font-weight: 600;
   margin-right: 10px;
   color: black;
}

.select_drop {
   width: 150px;
   height: 30px;
   color: black;
   font-size: 16px;
   font-weight: 600;
}

.advertisementtab {
   font-size: 16px;
   padding: 10px 25px;
   color: var(--app-theme);
   border-radius: 5px;
   background: white;
   cursor: pointer;
   font-family: titillium web !important;
   font-weight: 400;
}

.advertisementtab_active {
   background: var(--app-theme);
   color: white;

}

.popup_content ul {
   list-style-type: none;
   padding: 10px;
}

.popup_content ul li {
   font-size: 12px;
   padding: 5px 0px;
   color: #2c2c2c;
}

.payment_status {
   background-color: #FFA447;
   color: #ffffff;
   padding: 4px 30px;
   border-radius: 4px;
   margin: 10px;
}
