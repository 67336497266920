.cell_eye {
  padding-left: 0px !important;
}

.ongoing_booking_table_div table tbody tr th:nth-child(1){
  padding-right: 20px !important;
  text-align: center !important;
}

.ongoing_booking_table_div table thead tr th:nth-child(1),
.ongoing_booking_table_div table thead tr th:nth-child(3),
.ongoing_booking_table_div table tbody tr td:nth-child(3),
.ongoing_booking_table_div table thead tr th:nth-child(4),
.ongoing_booking_table_div table tbody tr td:nth-child(4),
.ongoing_booking_table_div table thead tr th:nth-child(6),
.ongoing_booking_table_div table tbody tr td:nth-child(6),
.ongoing_booking_table_div table thead tr th:nth-child(7),
.ongoing_booking_table_div table tbody tr td:nth-child(7),
.ongoing_booking_table_div table thead tr th:nth-child(8),
.ongoing_booking_table_div table tbody tr td:nth-child(8),
.ongoing_booking_table_div table thead tr th:nth-child(9),
.ongoing_booking_table_div table tbody tr td:nth-child(10)
{
  text-align: center;
  /* justify-content: center  !important; */
}
.ongoing_booking_table_div table tbody tr td:nth-child(9){
  text-align:center !important;
  /* text-transform: lowercase !important; */
}


.arabic_cls .ongoing_booking_table_div table thead tr th:nth-child(10),
.arabic_cls .ongoing_booking_table_div table tbody tr th:nth-child(10),
.arabic_cls .ongoing_booking_table_div table thead tr th:nth-child(7),
.arabic_cls .ongoing_booking_table_div table tbody tr td:nth-child(7),
.arabic_cls .ongoing_booking_table_div table thead tr th:nth-child(5),
.arabic_cls .ongoing_booking_table_div table tbody tr td:nth-child(5),
.arabic_cls .ongoing_booking_table_div table thead tr th:nth-child(4),
.arabic_cls .ongoing_booking_table_div table tbody tr td:nth-child(4),
.arabic_cls .ongoing_booking_table_div table thead tr th:nth-child(3),
.arabic_cls .ongoing_booking_table_div table tbody tr td:nth-child(3),
.arabic_cls .ongoing_booking_table_div table thead tr th:nth-child(2),
.arabic_cls .ongoing_booking_table_div table tbody tr td:nth-child(2),
.arabic_cls .ongoing_booking_table_div table thead tr th:nth-child(1),
.arabic_cls .ongoing_booking_table_div table tbody tr td:nth-child(1)
{
  text-align: center !important;
  justify-content: center !important;
}

.arabic_cls .ongoing_booking_table_div table thead tr th:nth-child(8),
.arabic_cls .ongoing_booking_table_div table tbody tr td:nth-child(8)
{
  text-align: center !important;
}

.arabic_cls .ongoing_booking_table_div table thead tr th:nth-child(6),
.arabic_cls .ongoing_booking_table_div table tbody tr td:nth-child(6),
.arabic_cls .ongoing_booking_table_div table thead tr th:nth-child(9),
.arabic_cls .ongoing_booking_table_div table tbody tr td:nth-child(9)
{
  text-align: right !important;
  justify-content: right !important;
}

.ttttttt table thead tr th:nth-child(10){
  padding-left: 15px;
  text-align: center !important;
}