.media_active{
    color:var(--app-theme);
    font-size: 1.25rem;
    margin-top: 15px;
 }
 .media_title_container .labeltxt{
     color:#333 !important;
 }
 .media_title_container{
     padding:20px;
 }
  .green_upload_container{
     margin-bottom: 15px;
 }
 
 .mediadelete_container{
     display:flex;
     justify-content: center;
     align-items: center;
     width:100%;
     /* padding: 15px 0px 0px 0px; */
    margin: 2px 0px 10px 0px;
 }
 .detete_no{
    background-color: var(--app-theme) !important; 
    color:#fff !important;
    font-size: 16px !important;
    text-transform: capitalize !important;
    border-radius: 50px !important;
 }
 .detete_yes{
     background-color: var(--pink-color) !important;
     color:#fff !important;
     font-size: 16px !important;
     margin-left: 15px !important;
     text-transform: capitalize !important;
     border-radius: 50px !important;
 }
 .delete_para{
     font-size: 16px;
     color: black;
   
 }
 .media_title_container .MuiButton-root{
     min-width: 90px !important;
 }
 .record_delete_container .MuiDialog-paperWidthSm{
    width:55% !important;
 }
  /* .Dialogmodal h6 {
  color:var(--app-theme) !important;
 } */