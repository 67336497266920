.doctor_revenue .titlerevenue {
  font-size: 1.75rem;
  color: var(--app-theme);
}

.doctorphar_dashboard {
  display: flex;
  align-items: center;

}

.font_10 {
  font-size: 10px;
}

.doctor_revenue .MuiButton-outlinedSizeSmall {
  padding: 5px 9px !important;
}

/* .doctor_revenue_dashboard .formdiv .selectbox .ant-select-selection--single{
    border:none !important;
  } */
.doctor_revenue .formdiv .labeltxt {
  padding-bottom: 0px !important;
}

.uploadsmasterheader .labeltxt {
  color: rgba(0, 0, 0, 0.65) !important;
  margin-right: 10px !important;
  margin-bottom: 0px !important;
  position: relative;
  top: 4px;
  font-size: 1.5rem !important;
}

.patient_head {
  width: 22%;
  position: relative;
  font-size: 1.5rem;
  color: #333;
}

.doctor_patienttype .formdiv {
  width: 35%;

}

.doctor_patienttype {
  display: flex;
  align-items: center;

}

.doctor_revenue .formdiv .selectbox .ant-select-selection--single {
  padding: 0px !important;

}

.doctor_revenue .doctor_revenueplus-container {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  width: 50%;
  justify-content: space-between;
}

.plus-icon {
  cursor: pointer;
}

.clinic_details {
  color: white !important;
  font-size: 12px !important;
  background-color: rgb(82, 118, 238) !important;
  border: none;
  padding: 8px;
}

.clinic_details:focus {
  background-color: #303f9f !important;
}

/* .clinic_details
  {
    color:white !important;
    font-size: 1.5rem !important;
    
    border:none;
      font-family: 'Avenir-Black';
    padding:8px;
    
  } */

.clinic_group_details .MuiButton-root {
  text-transform: none !important;
  border-radius: none !important;
  padding: 5px 15px !important;

}

.clinic_date_details {
  display: flex;
  align-items: center;
  justify-content: center;

}

.doctor_revenue .formdiv {
  display: flex !important;
}

.MuiButtonGroup-root {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04), 0 4px 8px 0 rgba(0, 0, 0, 0.20) !important;
  border: none !important;
  border-radius: none !important;
}

.current_left {
  margin-right: 5px;
}

.current_right {
  margin-left: 5px;
}

.doctor_revenue .MuiButton-root {
  text-transform: none !important;
  border-radius: none !important;
  border: none !important;
  font-size: 15px !important;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04), 0 4px 8px 0 rgba(0, 0, 0, 0.20) !important;

}

.revenue-master-table {
  position: relative;
}

.revenueTotal {
  height: 40px;
  color: white;
  background-color: #510f30;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  display: flex;
  border-radius: 18px;
  padding: 5px 10px;
  font-weight: 500;
}

.revenueTotalDiv {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
}

.revenue-master-table table thead tr th:nth-child(2),
.revenue-master-table table tbody tr td:nth-child(2),
.revenue-master-table table thead tr th:nth-child(5),
.secontMStable table thead tr th:nth-child(4),
.revenue-master-table table tbody tr td:nth-child(5),
.buttonHeadtitle tr th:nth-child(4),
.revenue-master-table table thead tr th:nth-child(6),
.revenue-master-table table tbody tr td:nth-child(6),
.revenue-master-table table thead tr th:nth-child(7),
.revenue-master-table table tbody tr td:nth-child(7) {
  text-align: center;
}

.arabic_cls .doctor_revenue .titlerevenue {
  order: 1;
}



.arabic_cls .revenue-master-table table thead tr th:nth-child(6),
.arabic_cls .revenue-master-table table tbody tr td:nth-child(6),
.arabic_cls .revenue-master-table table thead tr th:nth-child(3),
.arabic_cls .revenue-master-table table tbody tr td:nth-child(3),
.arabic_cls .revenue-master-table table thead tr th:nth-child(2),
.arabic_cls .revenue-master-table table tbody tr td:nth-child(2),
.arabic_cls .revenue-master-table table thead tr th:nth-child(1),
.arabic_cls .revenue-master-table table tbody tr td:nth-child(1),
.arabic_cls .revenue-master-table table thead tr th:nth-child(7),
.arabic_cls .revenue-master-table table tbody tr th:nth-child(7) {
  text-align: center;
}

.arabic_cls .revenue-master-table table tbody tr td:nth-child(2){
  direction: rtl;
}

.arabic_cls .revenue-master-table table thead tr th:nth-child(4),
.arabic_cls .revenue-master-table table tbody tr td:nth-child(4)
 {
  text-align: right;
  direction: rtl;
}
.arabic_cls .revenue-master-table table thead tr th:nth-child(5) {
  text-align: right;
}
/* .arabic_cls .revenue-master-table table thead tr th:nth-child(5), */
.arabic_cls .revenue-master-table table tbody tr td:nth-child(5){
direction: rtl;
}



/* .arabic_cls .revenue-master-table table tbody tr td:nth-child(5) {
  display: flex;
  justify-content: center !important;
} */

.arabic_cls .revenueTotal {
  position: absolute;
  left: 65px;
}


.vertical_Dot {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100px;
}



/* toggler */

.toggler {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 15px 0;
}

.switches-container {
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  border-radius: 30px;
  width: fit-content;
  position: relative;
  display: flex;
  padding: 0;
  position: relative;
  background: white;
  line-height: 26px;
  border-radius: 3rem;
  margin-left: unset;
  margin-right: unset;
}

.switches-container input {
  visibility: hidden;
  position: absolute;
  top: 0;
}

.switches-container label {
  width: fit-content;
  padding: 1px 8px;
  margin: 0;
  text-align: center;
  cursor: pointer;
  color: #510f30;
  font-size: 14px;
}

.switch-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  z-index: 3;
  transition: transform .5s cubic-bezier(.77, 0, .175, 1);
}

.switch {
  border-radius: 3rem;
  background: #510f30;
  height: 100%;
  color: white !important;
}

.switch div {
  width: 100%;
  text-align: center;
  opacity: 0;
  display: block;
  color: white;
  transition: opacity .2s cubic-bezier(.77, 0, .175, 1) .125s;
  will-change: opacity;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 12px;
}

.rs_model_title {
  background-color: #510f30;
  color: #fff;
  text-transform: uppercase;
  border-radius: 0px 0px;
  display: flex;
  width: 100%;
  /* height: 50px; */
  font-size: 18px;
  font-family: 'Titillium Web';
  border-radius: 20px 20px 0px 0px;
  padding: 10px;
  justify-content: space-between;
}

.rs_close_icon {
  color: #ff0068;
  font-size: 24px !important;
  cursor: pointer;
}

.rs_Model_crd {
  border-radius: 10px;
}

.rs_recpit_crd {
  height: 200px;
  width: 250px;
  border-radius: 5px;
  background-color: #b2afb0;
}

.rs_Downloadimg {
  cursor: pointer;
}

.rs_segment_line {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 25px;
  cursor: pointer;
  padding: 0px;
  margin: 0 10px;
  width: 30%;
}

.rs_active {
  border-bottom: 2px solid #510f30;
  color: #510f30;
}

.rs_frame {
  width: 250px;
  border: 5px solid #70707073;
}

.model_title1 {
  background-color: #510F30;
  color: white;
  padding: 10px 21px;
  border-radius: 20px 20px 0 0;
}

.font_9 {
  font-size: 9px;
}


.rsframe {
  width: 300px;
  padding: 20px 15px;
  background: hsl(332.31deg 13.4% 80.98%);
  border: 1px dashed #510f30;
  display: flex;
  margin: 10px auto 0 auto;
  border-radius: 9px;
}

.modelTable {
  border: 1px solid black;
  font-size: 12px;
  text-align: center;
}

.revenue_Model {

  tr,
  tbody,
  td,
  th {
    font-size: 14px !important;
    font-weight: normal !important;

  }

  th {
    background-color: #510f30;
    color: white !important;

  }

  td {
    color: #000000 !important;
  }
}

.revenModelTop {
  font-weight: normal;
  color: #212529;

  h5 {
    font-size: 20px;
  }

  p {
    font-size: 16px;
  }
}

.allkwd_lbl {
  text-align: end;
  margin-bottom: 6px;
  font-size: 14px;
  color: rgb(255, 0, 104);
}

.RevenueMaster_zwf td:nth-child(6),
.RevenueMaster_zwf td:nth-child(7) {
  /* display: flex;
  justify-content: center;
  width: 100%; */
  /* background-color: red; */

  p {
    margin: 0 !important;
    width: 80%;
    text-align: right;
    padding-right: calc(20% - 1rem - 4px);
  }
}

.ccpR {
  padding-right: calc(25% - 4rem - -8px);
}
.member_rname{
  display: flex;
  align-items: center;
  margin: 0px !important;
}
